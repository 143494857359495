import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector, {CustomDetector, DetectorOptions} from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import {Device} from "@capacitor/device";
import {Capacitor} from "@capacitor/core";

import {API_ENDPOINT} from "./variables/variables";

const resourcesToBackend = require('i18next-resources-to-backend')

let deviceLanguage = 'en';

const getDeviceLang = async () => {
    try {
        const lang = await Device.getLanguageCode();
        deviceLanguage = lang.value.slice(0, 2);
    } catch (error) {
        console.error('Error getting device language:', error);
    }
};

const deviceLangDetector: CustomDetector = {
    name: 'DeviceLangDetector',
    lookup: (options: DetectorOptions): string => {
        if (Capacitor.isNativePlatform()) {
            return deviceLanguage;
        }

        return navigator.language.slice(0, 2);
    }
};

export const initializeI18n = async () => {
    await getDeviceLang();

    const languageDetector = new LanguageDetector();
    languageDetector.addDetector(deviceLangDetector);

    await i18n
        .use(Backend)
        .use(languageDetector)
        .use(initReactI18next)
        .init({
            supportedLngs: ['en', 'pl', 'de', 'sk', 'tr', 'ru', 'cs', 'nl', 'hu', 'ro', 'zh', 'pt', 'es', 'uk', 'fr'],
            fallbackLng: 'en',
            debug: false,
            detection: {
                order: ['DeviceLangDetector', 'localStorage', 'queryString', 'cookie', 'navigator'],
                lookupLocalStorage: '_cap_i18nextLng',
                cache: ['cookie', 'localStorage', 'queryString']
            },
            interpolation: {
                escapeValue: false
            },
            backend: {
                backends: [
                    HttpApi,
                    resourcesToBackend((language: string, namespace: string, callback: any) => {
                        import(`../public/locales/${language}/${namespace}.json`)
                            .then(({default: resources}) => {
                                callback(null, resources);
                            })
                            .catch((error) => {
                                callback(error, null);
                            });
                    })
                ],
                backendOptions: [{
                    loadPath: API_ENDPOINT + 'api/v2/translations-exact-people/{{lng}}',
                    crossDomain: true
                }]
            }
        });
};
import React, {useEffect, useRef, useState} from 'react';
import {IonHeader, IonPage} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {useTranslation} from 'react-i18next';
import {Profile} from "../../models/profile";
import {Preferences} from "@capacitor/preferences";
import AuditReportsPane from "./panes/auditReportsPane.component";
import {AuditType} from "../../enums/audit";
import SubNavigation, {SubNavigationOption} from "../../components/sub-navigation/subNavigation.component";
import {useHistory} from "react-router-dom";
import useNavigation from "../../services/navigation.service";
import {ReportsTabOptions} from "../mainPage/tabs/reports/reportsTab.component";
import AuditReportsPlannedPane from "@app/auditReport/panes/auditReportsPlannedPane.component";

export enum AuditTabOptions {
    QUALITY,
    BHP,
    PLANNED = 2
}

let timeout: number;
const AuditReports: React.FC = () => {
    const { t } = useTranslation();
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [selectedProfile, updateSelectedProfile] = useState<Profile | undefined>();

    const getProfileData = async () => {

        let profileId = await Preferences.get({'key': 'profile_id'});
        let profileType = await Preferences.get({'key': 'profile_type'});
        let profileCompanyName = await Preferences.get({'key': 'profile_company_name'});

        if (profileId.value !== null && profileType.value !== null && profileCompanyName.value !== null) {
            updateSelectedProfile({
                id: parseInt(profileId.value),
                type: profileType.value,
                company: {
                    name: profileCompanyName.value
                }
            })
        }
    };

    useEffect(() => {
        getProfileData();
    }, []);

    useEffect(() => {
        updateHeight();

        return () => { clearTimeout(timeout); };
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            timeout = setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    const options = [
        {
            name: t("auditReports.quality")
        },
        {
            name: t("auditReports.bhp")
        },
        {
            name: t("auditReports.planned")
        }
    ];

    const history = useHistory();
    const navigation = useNavigation(history);
    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || AuditTabOptions.QUALITY);
    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());
        updateSelectedOption(optionIndex);
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("auditReports.title")}</StyledIonTitle>
                    </StyledIonToolbar>

                    <SubNavigation options={options} defaultValue={selectedOption || ReportsTabOptions.DRAFT} onSelect={handleSelect} />
                </IonHeader>
                {
                    selectedOption === AuditTabOptions.QUALITY &&
                    <AuditReportsPane topEdge={topEdge} profile={selectedProfile} auditType={AuditType.QUALITY} />
                }
                {
                    selectedOption === AuditTabOptions.BHP  &&
                    <AuditReportsPane topEdge={topEdge} profile={selectedProfile} auditType={AuditType.BHP} />
                }
                {
                    selectedOption === AuditTabOptions.PLANNED &&
                    <AuditReportsPlannedPane topEdge={topEdge} profile={selectedProfile} />
                }
            </StyledIonContent>
        </IonPage>
    );
};

export default AuditReports;

import {appApi} from "./api.service";
import {Preferences} from "@capacitor/preferences";
import {AuditIncompatibilityActionType, AuditReportPenaltyType, AuditShift, AuditType} from "../enums/audit";
import {AuditReportFormModel, InconsistencyRecordFormModel} from "../models/auditReport";
import {getDefaultOffset} from "../utils/tools/time";
import {validateEmail} from "./validation.service";
import {int} from "@zxing/library/es2015/customTypings";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

let endpointPrefix = 'audit';

export const getAuditReports = async (type: AuditType) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/list/'+ type +'?profileId='+(profileId.value ? profileId.value : ''));
}

export const findSpecification = async (number: bigint | string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/find-specification/' + number + '?profileId='+profileId.value);
}

export const checkNewestSpecification = async (id: number) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/check-newest-specification/' + id + '?profileId='+profileId.value);
}

export const findOrder = async (number: bigint | string, findById: boolean = false) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/find-order/' + number + '?profileId='+profileId.value + (findById ? '&findById=1' : ''));
}

export const findWorker = async (number: bigint | string, findById: boolean = false) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/find-worker/' + number + '?profileId='+profileId.value + (findById ? '&findById=1' : ''));
}

export const getOrderComponentsNumbers = async (orderId: number|string, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/order-components-number/' + orderId+'?profileId='+profileId.value+ '&page='+page + '&searchText=' + searchText);
}

export const getOrderSpecifications = async (orderId: number|string, page: number = 1) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/order-specifications/' + orderId+'?profileId='+profileId.value + '&page='+page);
}

export const getProjectPlaceOfServiceOrders = async (projectId: number | string, placeOfServiceId: number|string, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/project-place-of-service-orders/' + projectId + '/' + placeOfServiceId + '?profileId='+profileId.value + '&page='+page + '&searchText=' + searchText);
}

export const getProjectPlaceOfServiceComponentsNumbers = async (projectId: number | string, placeOfServiceId: number|string, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/project-place-of-service-components-number/' + projectId + '/' + placeOfServiceId + '?profileId='+profileId.value + '&page='+page + '&searchText=' + searchText);
}

export const getPlaceOfServices = async (page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/place-of-services?profileId=' + profileId.value + '&page='+page + '&searchText=' + searchText);
}

export const getPlaceOfServicesProfiles = async (placeOfServiceId: string|number, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/place-of-service/profiles/' + placeOfServiceId +'?profileId=' + profileId.value + '&page='+page + '&searchText=' + searchText);
}

export const getWorkers = async (projectId: string|number, date: string, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/project/workers/' + projectId + '/' + date +'?profileId=' + profileId.value + '&page='+page + '&searchText=' + searchText);
}

export const getPlaceOfServicesProjects = async (placeOfServiceId: string|number, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/place-of-service/projects/' + placeOfServiceId +'?profileId=' + profileId.value + '&page='+page + '&searchText=' + searchText);
}

export const getPlaceOfServiceCompanyAdditionalEmails = async (placeOfServiceId: string|number, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/place-of-service-company/additional-emails/' + placeOfServiceId +'?profileId=' + profileId.value + '&page='+page + '&searchText=' + searchText);
}

export const getForms = async (type: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/forms/' + type +'?profileId=' + profileId.value);
}

export const getPenaltyProfiles = async (role: string, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/penalty/profiles/' + role +'?profileId=' + profileId.value + '&page='+page + '&searchText=' + searchText);
}

export const getInconsistencyRecords = async () => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/incompatibilities/list?profileId=' + profileId.value);
}

export const getInconsistencyRecord = async (id: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/incompatibilities/'+ id +'?profileId=' + profileId.value);
}

export const getInconsistencyRecordFormData = async (id: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/incompatibilities/form-data/'+ id +'?profileId=' + profileId.value);
}

export const resolveInconsistencyRecord = async (id: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.put(endpointPrefix + '/incompatibilities/resolve/'+ id +'?profileId=' + profileId.value);
}

export const postInconsistencyRecord = async (id: string, data: any) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.post(endpointPrefix + '/incompatibilities/'+ id +'/edit?profileId=' + profileId.value, transformInconsistencyRecordToApiFormat(data), {
        headers: {
            'No-Loader': true
        }
    });
}

export const getInconsistencyUsers = async (id: string, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/incompatibilities/'+ id +'/users?profileId=' + profileId.value + '&page='+page + '&searchText=' + searchText);
}


export const getCurrentShitPeriod = () => {
    let now = moment().format('H');

    if (now >= 6 && now < 14) {
        return AuditShift.MORNING;
    } else if (now >= 14 && now < 22) {
        return AuditShift.EVENING;
    } else {
        return AuditShift.NIGHT;
    }
}

export const getShitPeriodReadable = (shift: AuditShift) => {
    switch (shift) {
        case AuditShift.MORNING:
            return '06:00 - 14:00';
        case AuditShift.EVENING:
            return '14:00 - 22:00';
        case AuditShift.NIGHT:
            return '22:00 - 06:00';
    }
}

export const validateReport = (report: AuditReportFormModel, step: number) => {
    switch (step) {
        case 1:
            return validateStep1(report);
        case 2:
            return validateStep2(report);
        case 3:
            return validateStep3(report);
    }
}

export const validateStep1 = (report: AuditReportFormModel) => {
    if (report.type !== AuditType.BHP && !report.noWorkers && report.workers?.length === 0) {
        return false;
    }

    if (!report.date) {
        return false;
    }

    if (!report.order && !report.noOrder) {
        return false;
    }

    if (!report.placeOfService) {
        return false;
    }

    if (!report.project) {
        return false;
    }

    if (!report.coordinatorOrTeamLeader && !report.coordinatorOrTeamLeaderOther) {
        return false;
    }

    if (report.noOrder && !report.noOrderComment) {
        return false;
    }

    if (report.noComponent && !report.noComponentComment) {
        return false;
    }

    if (!report.componentNumber && !report.noComponent) {
        return false;
    }

    return true;
}

export const validateStep2 = (report: AuditReportFormModel) => {    

    if (!checkIfRandomInspectionIsNok(report) && report.form?.questions.length !== report.questions?.length) {
        return false
    }

    // obligatory justification when red button question clicked
    if (report.questions?.some( (question) => question.answer.value === 'NOK' && !question.nokDictionaryItem) ) {
        return false;
    }

    if (report.randomInspectionEnabled && report.form?.is_random_inspection) {
        if ((!report.randomInspection?.ok || !report.randomInspection?.nok || !report.randomInspection?.checked)) {
            return false
        }

        //check if sum ok and not ok is exact like checked items'
        if (((parseInt(report.randomInspection?.ok || '0')) + (parseInt(report.randomInspection?.nok || '0'))) !== parseInt(report.randomInspection?.checked || '0')) {
            return false
        }
    }

    // financial fees - all abligatory beside comment
    if ( report.userPenalties?.some( (penalty) => !(penalty.person || penalty.personOther) || !penalty.type)) {
        return false;
    }

    if (report.userPenalties) {
        for (let user of report.userPenalties) {
            if (user.penalties) {
                if (user.penalties.length === 0) {
                    return false;
                }

                for (let penalty of user.penalties) {
                    if (!penalty.amount || !penalty.group || !penalty.item) {
                        return false;
                    }
                }
            } else {
                return false;
            }
        }
    }

    return true;
}

export const validateStep3 = (report: AuditReportFormModel) => {

    if (report.additionalEmails) {
        for (let additionalEmail of report.additionalEmails) {
            if (!additionalEmail || (additionalEmail && !validateEmail(additionalEmail))) {
                return false;
            }
        }
    }

    if (report.sendToClient && report.clientEmails) {

        if (report.clientEmails.length === 0) {
            return false;
        }

        for (let clientEmail of report.clientEmails) {
            if (!clientEmail || (clientEmail && !validateEmail(clientEmail))) {
                return false;
            }
        }
    }

    if (!report.coordinatorEmail || (report.coordinatorEmail && !validateEmail(report.coordinatorEmail))) {
        return false;
    }

    if (!report.regionalManagerEmail || (report.regionalManagerEmail && !validateEmail(report.regionalManagerEmail))) {
        return false;
    }

    return true;
}

export const validateInconsistencyRecord = (record: InconsistencyRecordFormModel, isNokSelected: boolean | undefined) => {

    if (!record.actionTerm || !record.user || !record.status) {
        return false;
    }

    if (isNokSelected && !record.nokDictionaryItem) {
        return false;
    }

    if (record.actions) {
        for (let action of record.actions) {
            if (!action.type || !action.comment) {
                return false;
            }
        }
    }

    return true;
}

export const checkIfAnyAnswerIsNotOk = (report: AuditReportFormModel) => {
    return report.questions?.some((questionAnswer) => questionAnswer.answer.value === 'NOK')
}

export const checkIfRandomInspectionIsNok = (report: AuditReportFormModel) => {
    return report.form?.is_random_inspection && report.randomInspection?.nok && parseInt(report.randomInspection?.nok) > 0;
}

export const getButtonType = (type:string) => {
    if (type === 'OK') {
        return 'positive'
    } else if (type === 'NOK') {
        return 'negative'
    } else {
        return 'neutral'
    }
}

export const saveAudit = async (report: AuditReportFormModel, type: string, plannedAudit: string|undefined) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    let endpoint = endpointPrefix + '/new/'+ type +'?profileId='+profileId.value
    if (plannedAudit) {
        endpoint = `${endpoint}&plannedAudit=${plannedAudit}`;
    }
    return appApi.post(endpoint, transformToApiFormat(report), {
        headers: {
            'No-Loader': true
        }
    });
}

const transformInconsistencyRecordToApiFormat = (record: InconsistencyRecordFormModel) => {
    return {
        audit_incompatibility_record: {
            user: record.user?.id,
            status: record.status,
            actionTerm:  moment(record.actionTerm, moment.ISO_8601).utcOffset(getDefaultOffset(), false).format('YYYY-MM-DD'),
            actionPerfecting: record.actions.find(value => value.type === AuditIncompatibilityActionType.PERFECTING)?.comment,
            actionCorrective: record.actions.find(value => value.type === AuditIncompatibilityActionType.CORRECTIVE)?.comment,
            actionImmediate: record.actions.find(value => value.type === AuditIncompatibilityActionType.IMMEDIATE)?.comment,
            auditReportQuestion: {
                nokDictionaryItem: record.nokDictionaryItem?.id,
                answer: record.answer?.value,
            },
            comment: record.comment
        }
    }
}

const transformToApiFormat = (report: AuditReportFormModel) => {
    return {
        audit_report: {
            form: report.form?.id,
            shiftPeriod: report.shiftPeriod,
            specification: report.specification?.id,
            order: report.order?.id,
            componentNumber: report.componentNumber?.id,
            placeOfService: report.placeOfService?.id,
            project: report.project?.id,
            coordinatorOrTeamLeader: report.coordinatorOrTeamLeader?.id,
            coordinatorOrTeamLeaderOther: report.coordinatorOrTeamLeaderOther,
            date:  moment(report.date, moment.ISO_8601).utcOffset(getDefaultOffset(), false).format('YYYY-MM-DD'),
            comment: report.comment,
            noOrderComment: report.noOrderComment,
            noComponentComment: report.noComponentComment,
            workers: report.workers?.map((worker) => {
                return worker.id
            }),
            questions: report.questions?.map((question) => {
                return {
                    nokDictionaryItem: question.nokDictionaryItem?.id,
                    nokComment: question.nokComment,
                    answer: question.answer.value,
                    template: question.template_id,
                    question: question.label,
                    questionRef: question.id,
                    files: question.files?.map((file) => {
                        return {
                            path: file.path,
                            originalName: file.original_name,
                            hash: file.hash,
                            extension: file.extension,
                            mimeType: file.mime_type,
                            size: file.size,
                        }
                    }),
                }
            }),
            userPenalties: report.userPenalties?.map((penalty) => {
                return {
                    type: penalty.type,
                    person: penalty.type !== AuditReportPenaltyType.WORKER ? penalty.person?.id : null,
                    worker: penalty.type === AuditReportPenaltyType.WORKER ? penalty.person?.id : null,
                    personOther: penalty.personOther,
                    penalties: penalty.penalties?.map((penaltyItem) => {
                        return {
                            groupName: penaltyItem.group?.name,
                            name: penaltyItem.item?.name_penalty,
                            amount: penaltyItem.amount,
                            comment: penaltyItem.comment,
                        }
                    })
                }
            }),
            sendToClient: report.sendToClient ? '1' : '0',
            clientEmails: report.clientEmails,
            coordinatorEmail: report.coordinatorEmail,
            regionalManagerEmail: report.regionalManagerEmail,
            additionalEmails: report.additionalEmails,
            partsVerificationQuantity: report.randomInspection?.amountInPackage,
            partsVerificationChecked: report.randomInspection?.checked,
            partsVerificationOk: report.randomInspection?.ok,
            partsVerificationNok: report.randomInspection?.nok,
        }
    }
}
export const getAuditReportsPlanned = async () => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/planned/list/?profileId='+(profileId.value ? profileId.value : ''));
}

export const getAuditReportPlanned = async (plannedAudit: int) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/planned/'+plannedAudit+'?profileId='+(profileId.value ? profileId.value : ''));
}
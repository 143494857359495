import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Loading from "./components/loading/loading.component";
import {initializeI18n} from "./i18n";

const renderApp = async () => {
    await initializeI18n();

    ReactDOM.render(
        <Suspense fallback={<Loading />}>
            <App />
        </Suspense>,
        document.getElementById('root')
    );
};

renderApp()
    .catch((error) => {
        console.log('Error while rendering App component')
        console.error(error);
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useContext, useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {
    IonAlert,
    IonButtons,
    IonCol,
    IonGrid,
    IonHeader,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    IonToast,
} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage, StyledSocialButton} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import {ScanButton, StyledContent} from './reportPage.style';
import StaticPane from '../../components/pane/static-pane.component';
import {useTranslation} from 'react-i18next';
import {StyledInput} from '../../components/form/input/input.style';
import ReportRowForm, {DataSelectable} from "./components/report/reportRowForm.component";
import {
    checkSpecification,
    findContainerById,
    findOrderById,
    getNewestSpecification,
    getPriceRates,
    getReport,
    getReports,
    isEmptyReport,
    minReportDate,
    saveReport,
    transformApiFormatToReport,
    validateAvgTimePerComponent,
    validateLogisticContainer,
    validateQuarantinePeriod,
    validateReport,
    validateTimeOverlap
} from "../../services/report.service";
import AddImage from "../../assets/images/e-add-blue.svg";
import {Links} from "../links";
import {ReportsContext} from "../mainPage/tabs/reports/panes/reportsPane.component";
import PageLeavingGuard from "../../components/page-leaving/pageLeavingGuard.component";
import {useForm} from "react-hook-form";
import {ReportStatus} from "../../enums/reportStatus";
import {Preferences} from "@capacitor/preferences";
import {Profile} from "../../models/profile";
import SaveConfirmationModal from "./modals/saveConfirmationModal.component";
import DateInput from "../../components/form/input/date.component";
import {ReportSetting} from "../../enums/report";
import {TimepickerMinutesIterationsType} from "../../enums/timepickerMinutesIterationsType";
import {PricingType} from "../../enums/pricing";
import {getNumberOfWorkDays} from "../../utils/tools/time";
import {ProfileType} from "../../enums/profileType";
import {StyledTextarea} from "@components/form/input/textarea.style";
import QrImage from "@assets/images/barcode-qr-1.svg";
import {BarcodeScanner} from "@capacitor-community/barcode-scanner";
import QrScannerModal from "@app/reportPage/modals/qrScannerModal.component";
import {getActiveContract} from "@services/contract.service";
import {Contract as ContractModel} from "@models/contract";
import {getWorkerId} from "@utils/tools/worker";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

interface ReportPageManager {
    setFormSubmitted: Function;
    getFormSubmitted: Function;
}

const contextReportPage: ReportPageManager = {
    setFormSubmitted: () => {

    },
    getFormSubmitted: () => {

    }
};

export const ReportPageContext = React.createContext<ReportPageManager>(contextReportPage);

export type DefectGroup = {
    id?: string;
    defects: Defect[];
    quantity?: number;
    repaired?: number;
    notRepaired?: number;
}

export type Defect = {
    id?: string;
    name: string;
    other?: string;
    defect?: DataSelectable;
}

export type Component = {
    id?: string;
    name: string | undefined;
    number?: string | undefined;
}

export type LogisticContainer = {
    id?: string;
    main_number?: string;
    additional_number?: string;
    quantity?: number;
    checked_parts?: number;
    component_number: Component;
}

export type ReportRowComments = {
    comment: string;
    partNumber: string;
    additionalInfoA: string;
    additionalInfoB: string;
}

export type ReportRowFile = {
    extension: string;
    hash: string;
    mime_type: string;
    original_name: string;
    path: string;
    size: number;
    download_url: string;
}

export type ReportRowWorker = {
    personId: string;
    number?: string;
    avatarUrl?: string | null;
    name: string;
    from?: string;
    to?: string;
    hoursHumanized?: string;
    pricingRate?: DataSelectable;
    rate?: DataSelectable;
    workerOvertime?: boolean;
    clientOvertime?: boolean;
}

export type ReportRowMeasurement = {
    measuring_equipment_item_category?: DataSelectable;
    measuring_equipment_order_item?: DataSelectable | null;
    multiplier?: number;
    missing?: boolean;
    hide?: boolean;
    description?: string;
}

export type ReportRow = {
    component: Component;
    quantity?: number | string;
    defectGroups: DefectGroup[];
    comments: ReportRowComments;
    components?: ReportRowComponent[];
    files: ReportRowFile[];
    workers: ReportRowWorker[];
    measurements?: ReportRowMeasurement[];
    skipMeasurements?: boolean;
    deliveryDate?: string,
}

export type ReportRowComponent = {
    quantity?: number | string;
    componentNumberText: string;
    serialNumber: string;
    levNumber: string;
    deliveryNote: string;
    comment: string;
    batchNumber: string;
    materialNumber: string;
    defectGroups: DefectGroup[];
    files: ReportRowFile[];
    new?: boolean,
    id?: string,
}

export type Order = {
    id: string;
    sub_type: string;
    pricing_type: PricingType,
    formatted_number: string;
    factory_number: string;
    logistic_container: boolean;
    complaint_number?: string;
    working_day_end?: string;
    max_components?: number;
    hide_other_persons?: boolean;
    hours_for_select_prev_day?: number;
    days_back_coordinator?: number,
    days_back_team_leader?: number,
    enable_nested_reporting: boolean;
    enable_single_components: boolean;
    is_extended_reporting: boolean;
    nested_reporting_settings: ReportSetting[],
    single_components_settings: ReportSetting[],
    nested_reporting_components: string[];
    specification: {
        id: number;
        newest: boolean;
        hasMeasurements: boolean;
    },
    use_time_iterations?: boolean;
    reporting_iteration_type?: TimepickerMinutesIterationsType;
    days_for_quarantine?: number,
    is_client_order?: boolean,
    is_enable_single_label_element_scan: boolean;
    scanning_settings: {
        prefix: boolean;
        manual: boolean;
        qr: boolean;
    },
    ep2_scanner_timeout?: string;
    is_hide_overtime: boolean;
};

export type Report = {
    specificationId: string;
    orderId?: string;
    reportId?: string;
    orderNumber?: string;
    orderFactoryNumber?: string;
    serviceDate: string;
    logisticContainerAdditionalNumber?: string;
    logisticContainer?: LogisticContainer;
    rows: ReportRow[];
    status?: string;
};

type SaveActionParams = {
    draft: boolean;
    stay: boolean;
    skipLimits?: boolean;
    closeContainer?: boolean;
}

type SaveAction = {
    func: (...args: any[]) => Promise<void>;
    params: SaveActionParams;
};

interface RouterProps {
    orderId: string;
    specificationId: string;
    reportId?: string;
    reportPreview?: string;
    containerId?: string;
}

interface ReportProps extends RouteComponentProps<RouterProps> {
}

const ReportPage: React.FC<ReportProps> = ({match}) => {
    const reportsContext = useContext(ReportsContext);
    const history = useHistory();
    const {t} = useTranslation();

    const {params: {orderId, specificationId, reportId, reportPreview, containerId}} = match;

    let reportPreviewBoolean = (reportPreview === '1');

    const [formSubmitted, setFormSubmitted] = useState<any>(false);
    const [reportIdState, setReportIdState] = useState<any>(reportId);
    const [showToast, setShowToast] = useState(false);
    const [formSaved, setFormSaved] = useState(false);
    const [outdatedSpecification, updateOutdatedSpecification] = useState(false);
    const [toast, setToast] = useState<any>('');

    const contextReportPage = useContext(ReportPageContext);
    contextReportPage.setFormSubmitted = setFormSubmitted;
    contextReportPage.getFormSubmitted = () => {
        return formSubmitted;
    };

    const [report, setReport] = useState<Report | null>();
    const [order, setOrder] = useState<Order | null>();
    const [logisticContainer, setLogisticContainer] = useState<LogisticContainer | null>();
    const [maxInContainer, setMaxInContainer] = useState(false);

    const [preSaveAction, setPreSaveAction] = useState<SaveAction | null>(null);
    const [postSaveAction, setPostSaveAction] = useState<SaveAction | null>(null);

    const [showSaveAlert, setShowSaveAlert] = useState(false);
    const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState(false);
    const [closeContainer, setCloseContainer] = useState(false);
    const [showLoading, updateShowLoading] = useState(false);

    const [showPreSaveValidationAlert, setShowPreSaveValidationAlert] = useState<boolean>(false);
    const [showPostValidationAlert, setShowPostValidationAlert] = useState<boolean>(false);
    const [validationAlertTitle, setValidationAlertTitle] = useState<string>('');
    const [validationStep, setValidationStep] = useState<number>(1);
    const [selectedProfile, updateSelectedProfile] = useState<Profile | undefined>();
    const [workerActiveContract, setWorkerActiveContract] = useState<ContractModel | null>();

    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [scanField, setScanField] = useState<string>('');
    const [showScanModal, setShowScanModal] = useState<boolean>(false);

    const getProfileData = async () => {
        let profileId = await Preferences.get({'key': 'profile_id'});
        let profileType = await Preferences.get({'key': 'profile_type'});
        let profileCompanyName = await Preferences.get({'key': 'profile_company_name'});

        if (profileId.value !== null && profileType.value !== null && profileCompanyName.value !== null) {
            updateSelectedProfile({
                id: parseInt(profileId.value),
                type: profileType.value,
                company: {
                    name: profileCompanyName.value
                }
            })
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleSave = async () => {
        setFormSubmitted(true);

        if (report && order) {
            let manualDefect = false;
            if (selectedProfile) {
                for (let row of report.rows) {
                    for (let defectGroup of row.defectGroups) {
                        for (let defect of defectGroup.defects) {
                            if (defect.other && !defect.defect) {
                                manualDefect = true;
                            }
                        }
                    }
                }
            }

            if (!manualDefect) {
                if (isEmptyReport(report, specificationId == '0', order.enable_single_components)) {
                    setFormSubmitted(false);
                    setShowSaveAlert(true);
                } else {
                    let overlap = validateTimeOverlap(report);
                    if (overlap.length > 0) {
                        setToast(t("reportPage.validation.time_overlap") + ': ' + overlap.join(', '));
                        setShowToast(true);
                    } else {
                        if (validateReport(report, order, selectedProfile, specificationId == '0', logisticContainer)) {
                            setShowSaveAlert(true);
                        } else {
                            setToast(t("reportPage.validation.commonError"));
                            setShowToast(true);

                            const errorElement = document.querySelector(
                                `.hasErrors`
                            )

                            if (errorElement) {
                                errorElement.scrollIntoView({behavior: 'smooth'})
                            }
                        }
                    }
                }
            } else {
                setToast(t("reportPage.validation.manualDefectPresent"));
                setShowToast(true);
            }
        }
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useForm({mode: 'all'});

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);
    const content = useRef<HTMLDivElement>(null);

    const addRow = () => {
        if (report) {
            let components = [];
            if (order?.is_extended_reporting) {
                components.push({
                    comment: '',
                    defectGroups: [],
                    files: [],
                    componentNumberText: '',
                    quantity: order.enable_single_components ? '1' : '',
                    deliveryNote: '',
                    levNumber: '',
                    serialNumber: '',
                    batchNumber: '',
                    materialNumber: '',
                    id: Math.random().toString(),
                } as ReportRowComponent);
            }

            setReport({
                ...report,
                rows: [...report.rows, {
                    component: {
                        name: ''
                    },
                    defectGroups: [],
                    files: [],
                    components: components,
                    comments: {
                        comment: '',
                        partNumber: '',
                        additionalInfoA: '',
                        additionalInfoB: ''
                    },
                    workers: []
                }]
            });
        }
    };

    const handleScan = async (value: string) => {
        if (scanField) {
            if (value.includes('S')) {
                value = value.replace('S', '');
                if (report) {
                    value = value.replace('S', '');
                    report.logisticContainerAdditionalNumber = value;

                    setReport({
                        ...report,
                        logisticContainerAdditionalNumber: report.logisticContainerAdditionalNumber
                    })
                }
                stopScan();

                setShowScanModal(false);
                setScanField('');
            }
        }
    };

    const startScan = async (field: string) => {
        setScanField(field);
        setShowScanModal(true);
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({force: true});

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    const scanContainerAdditionalNumber = async (field: string) => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan(field);
    }

    const handleRowChange = (row: ReportRow, index: number) => {
        if (report) {
            if (order && order.is_extended_reporting) {
                let sum = 0;

                row.components?.forEach((c) => {
                    if (c.quantity) {
                        sum += parseInt(c.quantity.toString());
                    }
                })
                row.quantity = sum;
            }
            let componentsSum = 0;
            let checkerParts = 0;
            if (row.components) {
                componentsSum = row.components.length;
            }
            if (logisticContainer?.checked_parts) {
                checkerParts = logisticContainer.checked_parts;
            }

            if (logisticContainer?.quantity != 0 && logisticContainer?.quantity == (componentsSum + checkerParts)) {
                setMaxInContainer(true);
            } else {
                setMaxInContainer(false);
            }

            report.rows.splice(index, 1, row);
            setReport({
                ...report,
                rows: [...report.rows]
            });
        }
    }

    const handleRowRemove = (index: number) => {
        if (report) {
            report.rows.splice(index, 1);

            setReport({
                ...report,
                rows: [...report.rows]
            });
        }
    }

    useEffect(() => {
        getProfileData();
        loadReport();
        loadWorkerActiveContract();
    }, []);

    useEffect(() => {
        const interval = setInterval(checkNewestSpecification, 60000);
        return () => clearInterval(interval);
    }, [order]);

    const updateList = async (draft: boolean) => {
        await getReports(draft ? ReportStatus.DRAFT : ReportStatus.NOT_VERIFIED, null)
            .then(response => {
                reportsContext.setReports(response.data);
            });
    }

    const checkNewestSpecification = async () => {
        if (specificationId != '0' && order && !reportPreviewBoolean) {
            await getNewestSpecification(order.id)
                .then(response => {
                    if (response.data.id != specificationId) {
                        updateOutdatedSpecification(true);
                    }
                });
        }
    }

    const saveButtons = () => {
        if (logisticContainer || report?.logisticContainer) {
            return [
                {
                    id: 'report-save-draft',
                    text: t('reportPage.saveAlert.saveDraft'),
                    cssClass: (outdatedSpecification || (selectedProfile && report && report.status && report.status != 'DRAFT')) ? 'alertButtonDisabled' : '',
                    handler: async () => {
                        setPreSaveAction({func: handleSaveReport, params: {draft: true, stay: false}});
                    }
                },
                {
                    id: 'report-save-draft-and-stay',
                    text: t('reportPage.saveAlert.saveDraftAndStay'),
                    cssClass: (outdatedSpecification || (selectedProfile && report && report.status && report.status != 'DRAFT')) ? 'alertButtonDisabled' : '',
                    handler: async () => {
                        setPreSaveAction({func: handleSaveReport, params: {draft: true, stay: true}});
                    }
                },
                {
                    id: 'report-save-and-quit',
                    text: t('reportPage.saveAlert.save'),
                    cssClass: report ? (isEmptyReport(report, specificationId == '0', order?.enable_single_components) ? 'alertButtonDisabled' : '') : '',
                    handler: async () => {
                        if (selectedProfile) {
                            setCloseContainer(false);
                            setPreSaveAction({func: handleSaveReport, params: {draft: false, stay: false}});
                        } else {
                            setShowSaveConfirmationModal(true);
                            setCloseContainer(false);
                        }
                    }
                },
                {
                    id: 'report-save-and-close-container',
                    text: t('reportPage.saveAlert.saveAndCloseContainer'),
                    cssClass: report ? (isEmptyReport(report, specificationId == '0', order?.enable_single_components) ? 'alertButtonDisabled' : '') : '',
                    handler: async () => {
                        if (selectedProfile) {
                            setCloseContainer(true);
                            setPreSaveAction({
                                func: handleSaveReport,
                                params: {draft: false, stay: false, skipLimits: false, closeContainer: true}
                            });
                        } else {
                            setShowSaveConfirmationModal(true);
                            setCloseContainer(true);
                        }
                    }
                }
            ];
        } else {
            return [
                {
                    text: t('reportPage.saveAlert.saveDraft'),
                    cssClass: (outdatedSpecification || (selectedProfile && report && report.status && report.status != 'DRAFT')) ? 'alertButtonDisabled' : '',
                    handler: async () => {
                        setPreSaveAction({func: handleSaveReport, params: {draft: true, stay: false}});
                    }
                },
                {
                    text: t('reportPage.saveAlert.saveDraftAndStay'),
                    cssClass: (outdatedSpecification || (selectedProfile && report && report.status && report.status != 'DRAFT')) ? 'alertButtonDisabled' : '',
                    handler: async () => {
                        setPreSaveAction({func: handleSaveReport, params: {draft: true, stay: true}});
                    }
                },
                {
                    text: t('reportPage.saveAlert.save'),
                    cssClass: report ? (isEmptyReport(report, specificationId == '0', order?.enable_single_components) ? 'alertButtonDisabled' : '') : '',
                    handler: async () => {
                        if (selectedProfile) {
                            setCloseContainer(false);
                            setPreSaveAction({func: handleSaveReport, params: {draft: false, stay: false}});
                        } else {
                            setShowSaveConfirmationModal(true);
                            setCloseContainer(false);
                        }
                    }
                }
            ];
        }

    }

    const loadLogisticContainer = async () => {
        if (logisticContainer === undefined) {
            if (containerId) {
                let tmpLogisticContainer = await findContainerById(containerId).then((response) => {
                    let logisticContainer = response.data as LogisticContainer;
                    setLogisticContainer(logisticContainer);

                    return logisticContainer;
                }).catch(() => {
                    history.replace(Links.main + Links.reports);
                })

                return tmpLogisticContainer;
            }
        }
    }

    const loadOrder = async () => {
        if (order === undefined) {
            if (specificationId != '0') {
                let tmpOrder = await checkSpecification(specificationId, reportIdState, true).then((response) => {
                    let order = response.data as Order;
                    if (!reportPreviewBoolean && parseInt(specificationId) != order.specification.id) {
                        updateOutdatedSpecification(true);
                    } else {
                        updateOutdatedSpecification(false);
                    }
                    setOrder(order);
                    return order;
                }).catch(() => {
                    history.replace(Links.main + Links.reports);
                })

                return tmpOrder;
            } else {
                let tmpOrder = await findOrderById(orderId, true).then((response) => {
                    let order = response.data as Order;
                    setOrder(order);

                    return order;
                }).catch(() => {
                    history.replace(Links.main + Links.reports);
                })

                return tmpOrder;
            }
        }
    }

    const loadReport = async () => {
        if (report === undefined) {
            if (containerId) {
                await loadLogisticContainer();
            }
            let o = await loadOrder();
            if (reportIdState && parseInt(reportIdState) !== 0) {
                await getReport(reportIdState).then((response) => {
                    setReport(transformApiFormatToReport(response.data));
                    if (response.data.logistic_container){
                        let l = response.data.logistic_container;
                        l.checked_parts = l.checked_parts - response.data.rows[0].checked_parts;
                        setLogisticContainer(l);
                    }
                }).catch((e) => {
                    history.replace(Links.main + Links.reports);
                })
            } else {
                let components = [];
                components.push({
                    comment: '',
                    defectGroups: [],
                    files: [],
                    componentNumberText: '',
                    quantity: o && o?.enable_single_components ? '1' : '',
                    deliveryNote: '',
                    levNumber: '',
                    serialNumber: '',
                    batchNumber: '',
                    materialNumber: '',
                    id: Math.random().toString(),
                } as ReportRowComponent);

                setReport({
                    orderId: order?.id,
                    specificationId: specificationId,
                    orderNumber: order?.formatted_number,
                    orderFactoryNumber: order?.factory_number,
                    logisticContainerAdditionalNumber: '',
                    serviceDate: moment().format(),
                    rows: [
                        {
                            component: {
                                name: ''
                            },
                            quantity: undefined,
                            defectGroups: [],
                            files: [],
                            components: components,
                            comments: {
                                comment: '',
                                partNumber: '',
                                additionalInfoA: '',
                                additionalInfoB: ''
                            },
                            workers: [],
                        }
                    ]
                });
            }
        }
    }

    const loadWorkerActiveContract = async () => {
        const workerId = await getWorkerId();

        if (workerId && workerId !== 'undefined') {
            const response = await getActiveContract();
            setWorkerActiveContract(response.data as ContractModel);
        }
    }

    const clearWorkersPricingRates = async () => {
        if (report?.serviceDate) {
            let rates = await getPriceRates(orderId, reportIdState, moment(report?.serviceDate).format('DD-MM-YYYY'))
                .then(response => {
                    return response.data as DataSelectable[];
                });

            for (let row of report.rows) {
                for (let worker of row.workers) {
                    if (worker.pricingRate && !rates.filter((element) => {
                        return worker.pricingRate?.id === element.id;
                    })) {
                        worker.pricingRate = undefined;
                        setFormSubmitted(true);
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (preSaveAction) {
            preSaveValidate();
        }
    }, [validationStep, preSaveAction]);

    const validationFunctions = [
        validateQuarantinePeriod,
        validateAvgTimePerComponent,
        validateLogisticContainer,
    ];

    const preSaveValidate = async () => {
        if (report && order) {
            if (validationStep === 1 && !preSaveAction?.params.draft && !validateQuarantinePeriod(order, report.serviceDate)) {
                handlePreSaveValidationAlertOpen('reportPage.quarantineAlert.title');
                return;
            }

            if (validationStep === 2 && !preSaveAction?.params.draft && !validateAvgTimePerComponent(report)) {
                handlePreSaveValidationAlertOpen('reportPage.avgTimePerComponentAlert.title');
                return;
            }

            if (validationStep === 3 && !preSaveAction?.params.draft && !validateLogisticContainer(maxInContainer, closeContainer)) {
                handlePreSaveValidationAlertOpen('reportPage.logisticContainerAlert.title');
                return;
            }
        }

        if (preSaveAction) {
            if (validationStep < validationFunctions.length) {
                setValidationStep(prevState => prevState + 1);
            } else {
                const action = preSaveAction;
                setPreSaveAction(null);
                await action.func(action.params);
            }
        }
    }

    const handleSaveReport = async ({draft, stay, skipLimits = false, closeContainer = false}: SaveActionParams) => {
        if (report && order) {
            updateShowLoading(true);

            try {
                const response = await saveReport(order, report, reportIdState, draft, skipLimits, containerId, closeContainer);
                updateList(draft);
                if (response.data.id) {
                    setReportIdState(response.data.id);
                }

                setFormSaved(true);
                if (!stay) {
                    history.replace(Links.main + Links.reports);
                }
            } catch (error) {
                if (error.response.status === 400) {
                    try {
                        let rowsErrors = error.response.data.errors.children.rows.children;
                        for (let rowError in rowsErrors) {
                            let workersError = rowsErrors[rowError].children.workers?.children;
                            for (let workerError in workersError) {
                                let endAtErrors = workersError[workerError].children.endAt?.errors;
                                if (endAtErrors && endAtErrors[0] === 'This value is not valid.') {
                                    setToast(t("reportPage.validation.workerHoursPassedShift"));
                                }

                                if (endAtErrors && endAtErrors[0] === 'This value should not be blank.') {
                                    setToast(t("reportPage.validation.workerEndTimeRequired"));
                                }

                                let workerErrors = workersError[workerError].children.worker?.errors;
                                if (workerErrors && workerErrors[0] === 'Worker for this report date already exists') {
                                    setToast(t("reportPage.validation.workerReportAlreadyExists"));
                                }

                                let rateError = workersError[workerError].children.rate?.errors;
                                if (!toast && rateError) {
                                    setToast(t("reportPage.validation.workerRateNotSelected"));
                                }

                                let pricingRateError = workersError[workerError].children.pricingRate?.errors;
                                if (!toast && pricingRateError) {
                                    setToast(t("reportPage.validation.workerPricingRateNotSelected"));
                                }
                            }

                            let componentsError = rowsErrors[rowError].children.components?.children;
                            for (let componentError in componentsError) {
                                let componentNumberTextErrors = componentsError[componentError].children.componentNumberText?.errors;
                                if (componentNumberTextErrors && componentNumberTextErrors[0].includes('already exists')) {
                                    setToast(t("reportPage.validation.componentNumberAlreadyExits"));
                                }

                                let levNumberErrors = componentsError[componentError].children.levNumber?.errors;
                                if (levNumberErrors && levNumberErrors[0].includes('already exists')) {
                                    setToast(t("reportPage.validation.levNumberAlreadyExits"));
                                }

                                let materialNumberErrors = componentsError[componentError].children.materialNumber?.errors;
                                if (materialNumberErrors && materialNumberErrors[0].includes('already exists')) {
                                    setToast(t("reportPage.validation.materialNumberAlreadyExits"));
                                }

                                let serialNumberErrors = componentsError[componentError].children.serialNumber?.errors;
                                if (serialNumberErrors && serialNumberErrors[0].includes('already exists')) {
                                    setToast(t("reportPage.validation.serialNumberAlreadyExits"));
                                }

                                let batchNumberErrors = componentsError[componentError].children.batchNumber?.errors;
                                if (batchNumberErrors && batchNumberErrors[0].includes('already exists')) {
                                    setToast(t("reportPage.validation.batchNumberAlreadyExits"));
                                }

                                let deliveryNoteErrors = componentsError[componentError].children.deliveryNote?.errors;
                                if (deliveryNoteErrors && deliveryNoteErrors[0].includes('already exists')) {
                                    setToast(t("reportPage.validation.deliveryNoteAlreadyExits"));
                                }
                            }

                            if (rowsErrors[rowError].children.deliveryDate.errors) {
                                setToast(t("reportPage.validation.deliveryDateInvalid"));
                            }
                        }
                    } catch (e) {
                        setToast(t("reportPage.validation.commonError"));
                    }

                    try {
                        let message = error.response.data.errors.children.limit.errors[0];
                        if (message && reportIdState === '0' && order.is_client_order && selectedProfile?.type && (selectedProfile.type === ProfileType.COORDINATOR || selectedProfile.type === ProfileType.TEAM_LEADER)) {
                            handlePostSaveValidationAlertOpen('reportPage.limitAlert.title', {
                                func: handleSaveReport,
                                params: {draft: draft, stay: stay, skipLimits: true}
                            });
                            updateShowLoading(false);
                            return;
                        } else {
                            if (!toast && message) {
                                setToast(t("reportPage.validation.limit"));
                            }
                        }
                    } catch (e) {
                    }

                    try {
                        let message = error.response.data.errors.children.ep2ScannedSpecificationId.errors[0];
                        if (!toast && message) {
                            updateOutdatedSpecification(true);
                            setToast(t("reportPage.validation.not_newest_specification"));
                        }
                    } catch (e) {
                    }

                    if (toast !== '') {
                        setToast(t("reportPage.validation.commonError"));
                    }
                } else if (error.response.status === 403) {
                    try {
                        let message = error.response.data?.message;
                        if (message === 'ORDER_BLOCKED_BY_STATUS') {
                            setToast(t('reportsTab.orders.scanSpecificationPage.error.order_blocked_by_status'));
                        }
                    } catch (e) {
                    }
                } else {
                    setToast(t("common.serverErrorMsg"));
                }

                setShowToast(true);
                handleValidationAlertCancel();
            } finally {
                updateShowLoading(false);
            }

            handleValidationAlertCancel();
        }
    };

    const handlePreSaveValidationAlertConfirm = () => {
        setShowPreSaveValidationAlert(false);
        setTimeout(() => {
            setValidationStep(prevState => prevState + 1);
        }, 300);
    };

    const handlePostSaveValidationAlertConfirm = async () => {
        if (postSaveAction) {
            const action = postSaveAction;
            setPostSaveAction(null);
            await action.func(action.params);
        }
    };

    const handleValidationAlertCancel = () => {
        setShowPreSaveValidationAlert(false);
        setShowPostValidationAlert(false);

        setPreSaveAction(null);
        setPostSaveAction(null);

        setValidationStep(1);
    };

    const handlePreSaveValidationAlertOpen = (alertTitle: string) => {
        setShowPreSaveValidationAlert(true);
        setValidationAlertTitle(alertTitle);
    }

    const handlePostSaveValidationAlertOpen = (alertTitle: string, saveActionCallback: SaveAction) => {
        setShowPostValidationAlert(true);
        setValidationAlertTitle(alertTitle);
        setPostSaveAction(saveActionCallback);
    }

    const validateServiceDateForWorker = (date: string) => {
        if (selectedProfile) {
            return true;
        }

        if (!workerActiveContract) {
            return false;
        }

        const start = moment(workerActiveContract.start_at);
        const end = moment(workerActiveContract.end_at);

        return moment(date).isBetween(start, end, undefined, '[]');
    }

    return (
        <ReportPageContext.Provider value={contextReportPage}>
            <IonPage className="report">
                <StyledIonContent>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <IonButtons slot="start">
                                <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle className="wrap-text">
                                <div className="wrap-text">
                                    {!reportPreviewBoolean ? t('reportPage.title') : t('reportPage.preview')}
                                </div>
                            </StyledIonTitle>
                            {!reportPreviewBoolean &&
                                <IonButtons slot="end">
                                    <StyledHeaderButton className="save-btn" onClick={() => handleSave()}>
                                        <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                                    </StyledHeaderButton>
                                </IonButtons>
                            }
                        </StyledIonToolbar>
                    </IonHeader>
                    <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                        <StyledContent>
                            <div className="content full-height" ref={content}>
                                <IonGrid className="title">
                                    {outdatedSpecification &&
                                        <IonRow>
                                            <IonCol className="label">
                                                <IonText color="danger">
                                                    <h6>{t("reportPage.validation.not_newest_specification")}</h6>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    }
                                    <IonRow>
                                        <IonCol size="6" className="label">
                                            {t('reportPage.orderId')}
                                        </IonCol>
                                        <IonCol size="6" className="label">
                                            {t('reportPage.orderDate')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6" className="label">
                                            <StyledInput disabled={true} value={order?.formatted_number}/>
                                        </IonCol>
                                        <IonCol size="6" className="date-field">
                                            <DateInput
                                                presentation="date"
                                                format="DD.MM.YYYY"
                                                onChange={value => {
                                                    if (validateServiceDateForWorker(value)) {
                                                        setShowToast(false);
                                                        if (report) {
                                                            report.serviceDate = value;
                                                            setReport({
                                                                ...report,
                                                                serviceDate: report.serviceDate
                                                            })

                                                            clearWorkersPricingRates();
                                                        }
                                                    } else {
                                                        setToast(t("reportPage.validation.noActiveContract"));
                                                        setShowToast(true);
                                                    }
                                                }}
                                                disabled={reportPreviewBoolean}
                                                min={order && report ? minReportDate(order, report, selectedProfile) : ''}
                                                max={moment().format('YYYY-MM-DD')}
                                                cancelText={t("common.dateCancel")}
                                                doneText={t("common.dateDone")}
                                                placeholder={t("common.select")}
                                                className={formSubmitted && report && !report.serviceDate ? 'hasErrors' : ''}
                                                value={(report && report.serviceDate) ? report.serviceDate : moment().format('YYYY-MM-DD')}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    {order && order.logistic_container &&
                                        <>
                                            <IonRow>
                                                <IonCol size="12" className="label">
                                                    {t('reportPage.logisticContainerAdditionalNumber')}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="6" className="label">
                                                    <StyledInput disabled={reportPreviewBoolean}
                                                                 onKeyPress={event => {
                                                                     if (event.key == 'Enter' && !reportPreviewBoolean) {
                                                                         const input = event.target as HTMLInputElement;
                                                                         input.blur();
                                                                     }
                                                                 }}
                                                                 className="full-width"
                                                                 onChange={value => {
                                                                     if (report) {
                                                                         report.logisticContainerAdditionalNumber = value.target.value;

                                                                         setReport({
                                                                             ...report,
                                                                             logisticContainerAdditionalNumber: report.logisticContainerAdditionalNumber
                                                                         })
                                                                     }
                                                                 }}
                                                                 value={report?.logisticContainerAdditionalNumber}
                                                                 placeholder={t('reportPage.reportForm.enter')}/>
                                                </IonCol>
                                                <ScanButton>
                                                    <IonCol size="6" className="scanContainerAdditionalNumber">
                                                        <div
                                                            onClick={() => scanContainerAdditionalNumber('logisticContainerAdditionalNumber')}>
                                                            <span>{t("reportPage.logisticContainerAdditionalNumberScan")}</span>
                                                            <img src={QrImage}/>
                                                        </div>
                                                    </IonCol>
                                                </ScanButton>
                                            </IonRow>
                                            {
                                                formSubmitted && ((report?.logisticContainer?.additional_number && !report?.logisticContainerAdditionalNumber) ||
                                                (logisticContainer?.additional_number && !report?.logisticContainerAdditionalNumber)) &&
                                                <p className="errorMessage">{t('reportPage.reportForm.logisticContainerAdditionalNumberRequired')}</p>
                                            }
                                        </>
                                    }


                                    {order && (order.factory_number || order.complaint_number) &&
                                        <>
                                            <IonRow>
                                                {order.factory_number && <IonCol size="6" className="label">
                                                    {t('reportPage.orderFactoryNumber')}
                                                </IonCol>}
                                                {order.complaint_number && <IonCol size="6" className="label">
                                                    {t('reportPage.orderComplaintNumber')}
                                                </IonCol>}
                                            </IonRow>

                                            <IonRow>
                                                {order.factory_number && <IonCol size="6" className="label">
                                                    <StyledInput disabled={true} value={order.factory_number}/>
                                                </IonCol>}
                                                {order.complaint_number && <IonCol size="6" className="label">
                                                    <StyledInput disabled={true} value={order.complaint_number}/>
                                                </IonCol>}
                                            </IonRow>
                                        </>
                                    }
                                </IonGrid>
                                {

                                    order && report && report.rows.map((row, key) =>
                                        <ReportRowForm
                                            report={report}
                                            order={order}
                                            profile={selectedProfile}
                                            reportPreview={reportPreviewBoolean}
                                            maxInContainer={maxInContainer}
                                            orderId={order.id}
                                            reportId={reportIdState}
                                            specificationId={specificationId}
                                            orderType={order.sub_type}
                                            maxComponents={order.max_components}
                                            logisticContainerComponent={logisticContainer?.component_number}
                                            hideOtherPersons={order.hide_other_persons}
                                            row={row}
                                            onChange={(row) => handleRowChange(row, key)}
                                            onRemove={(row) => handleRowRemove(key)}
                                            onSubmit={() => {
                                                handleSave()
                                            }}
                                            key={'rows_' + key}/>
                                    )
                                }
                                {
                                    formSubmitted && report && report.rows.length === 0 &&
                                    <p className="errorMessage">{t('reportPage.reportForm.rowsRequired')}</p>
                                }

                                {reportPreview != '1' && (!order || !order.logistic_container) &&
                                    <StyledSocialButton className="bottom-margin black-text report-add" onClick={async () => {
                                        await addRow();

                                        content.current?.scrollTo({
                                            behavior: 'smooth',
                                            top: content.current?.scrollHeight
                                        });
                                    }}>
                                        <img className="button-image" src={AddImage}/>
                                        {!order?.is_extended_reporting ? t("reportPage.addButton") : t("reportPage.addButtonNestedReporting")}
                                    </StyledSocialButton>
                                }
                            </div>
                        </StyledContent>
                    </StaticPane>
                </StyledIonContent>
            </IonPage>
            <IonAlert
                isOpen={showPreSaveValidationAlert}
                backdropDismiss={false}
                header={t(validationAlertTitle)}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => handleValidationAlertCancel(),
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => handlePreSaveValidationAlertConfirm(),
                    }
                ]}
            />
            <IonAlert
                isOpen={showPostValidationAlert}
                backdropDismiss={false}
                header={t(validationAlertTitle)}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => handleValidationAlertCancel(),
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => handlePostSaveValidationAlertConfirm(),
                    }
                ]}
            />
            <IonAlert
                isOpen={showSaveAlert}
                onDidDismiss={() => setShowSaveAlert(false)}
                header={t('reportPage.saveAlert.title')}
                buttons={saveButtons()}
            />
            <SaveConfirmationModal
                isOpen={showSaveConfirmationModal}
                logisticContainer={logisticContainer}
                onConfirm={async () => {
                    setShowSaveConfirmationModal(false)
                    setPreSaveAction({
                        func: handleSaveReport,
                        params: {draft: false, stay: false, skipLimits: false, closeContainer: closeContainer}
                    });
                }}
                onCancel={() => {
                    setShowSaveConfirmationModal(false)
                }}/>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {
                    setShowToast(false);
                    setToast('');
                }}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />
            <QrScannerModal logisticContainer = {true} isOpen={showScanModal} onClose={() => {
                setShowScanModal(false);
                setScanField('');
            }} onSubmit={(value) => {
                handleScan(value);
            }}/>
            <PageLeavingGuard navigate={path => history.goBack()} when={!reportPreviewBoolean}
                              shouldBlockNavigation={location => {
                                  if (order) {
                                      if (formSaved) {
                                          return false;
                                      }
                                      if (location.pathname === Links.qrCodeWorker || location.pathname === (Links.choosePerson + '/' + order.id + '/' + moment(report && report.serviceDate ? report.serviceDate : null).format('DD-MM-YYYY')) || location.pathname === (Links.choosePersonExtended + '/' + order.id + '/' + specificationId + '/' + moment(report && report.serviceDate ? report.serviceDate : null).format('DD-MM-YYYY') + '/' + reportIdState) || location.pathname === (Links.report + '/' + order.id + '/' + specificationId + '/' + reportIdState + '/' + reportPreview + '/' + containerId)) {
                                          return false;
                                      }
                                      return true;
                                  }
                                  return false;
                              }}/>
            <IonLoading onDidDismiss={() => updateShowLoading(false)} isOpen={showLoading}/>
        </ReportPageContext.Provider>
    );
};

export default ReportPage;

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledIonModal, StyledModalContent} from '../../../components/modal/modal.style';
import {StyledFooter} from '../../../components/pane/pane.style';
import {IonCol, IonGrid, IonItem, IonLabel, IonRow, IonToast} from '@ionic/react';
import {StyledButton} from '../../../components/button/button.style';
import {Order, Report, ReportRow, ReportRowWorker} from "../reportPage.component";
import {DataSelectable} from "../components/report/reportRowForm.component";
import {
    checkSpecification,
    findOrderById,
    getReportNextMoment,
    getReportTimepickerMinutesIterations,
    getPriceRates,
    getWorkerRates,
    hoursHumanized
} from "../../../services/report.service";
import AvatarImage from "../../../assets/images/logo/x-black.png";
import {StyledInput} from "../../../components/form/input/input.style";
import ArrowDownImage from "../../../assets/images/down-arrow.svg";
import ArrowRightImage from "../../../assets/images/arrow-right.svg";
import {StyledIonLabel} from "../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../components/form/input/checkbox.style";
import {StyledSelectedWorkerExtended} from "../choosePersonPage.style";
import ListModal from "./listModal.component";
import moment, {ISO_8601} from "moment";
import DateInput from "../../../components/form/input/date.component";
import CloseImage from "../../../assets/images/e-remove.svg";
import {StyledContent} from "./commentsModal.style";
import {PricingType} from "../../../enums/pricing";

type MultipleExtendedWorkerModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (row: ReportRow) => void;
    row?: ReportRow | null;
    report: Report;
    order?: Order;
    orderId?: string;
    specificationId?: string | bigint;
};

const MultipleExtendedWorkerModal: React.FC<MultipleExtendedWorkerModalProps> = (props: MultipleExtendedWorkerModalProps) => {
    const {t} = useTranslation();

    const [show, updateShow] = useState<boolean>(false);

    const [selectedRow, updateSelectedRow] = useState<ReportRow | null>();
    const [selectedWorker, updateSelectedWorker] = useState<ReportRowWorker | null>();
    const [order, setOrder] = useState<Order | null>();

    const [pricingRates, updatePricingRates] = useState<DataSelectable[]>();
    const [showPricingRates, updateShowPricingRates] = useState<boolean>(false);

    const [workerRates, updateWorkerRates] = useState<DataSelectable[]>();
    const [showWorkerRates, updateShowWorkerRates] = useState<boolean>(false);

    const [toast, setToast] = useState<any>('');
    const [showToast, setShowToast] = useState(false);

    const updateSelectedPricingRate = (worker: ReportRowWorker, pricingRate: DataSelectable) => {
        worker.pricingRate = pricingRate;
        updateShowPricingRates(false);
        updateSelectedWorker(null)
    }

    const updateSelectedWorkerRate = (worker: ReportRowWorker, workerRate: DataSelectable) => {
        worker.rate = workerRate;
        updateShowWorkerRates(false);
        updateSelectedWorker(null);
    }

    const fetchPricingRates = async () => {
        if (props.orderId) {
            await getPriceRates(props.orderId, props.report.reportId, moment(props.report.serviceDate).format('DD-MM-YYYY'))
                .then(response => {
                    updatePricingRates(response.data);
                });
        }
    }

    const fetchWorkerRates = async (worker: ReportRowWorker) => {
        if (props.orderId && worker.number) {
            await getWorkerRates(props.orderId, worker.number, moment(props.report.serviceDate).format('DD-MM-YYYY'))
                .then(response => {
                    updateWorkerRates(response.data);
                });
        }
    }

    const loadOrder = async () => {
        const specificationId = props.specificationId;
        const reportId = props.report.reportId;
        const orderId = props.orderId;

        if (order === undefined) {
            if (specificationId && specificationId != '0') {
                await checkSpecification(specificationId, reportId, true).then((response) => {
                    let order = response.data as Order;
                    setOrder(order);
                })
            } else if (orderId) {
                await findOrderById(orderId, true).then((response) => {
                    let order = response.data as Order;
                    setOrder(order);
                })
            }
        }
    }

    const onStartDateChange = (worker: ReportRowWorker, key: number, value: string) => {
        worker.from = value;
        updateHoursHumanized(worker, key);
    };

    const onEndDateChange = (worker: ReportRowWorker, key: number, value: string) => {
        worker.to = value;
        updateHoursHumanized(worker, key);
    };

    const updateHoursHumanized = (worker: ReportRowWorker, key: number) => {
        if (selectedRow) {
            if (worker.from && worker.to) {
                let hours = hoursHumanized(moment(worker.from, ISO_8601).format('HH:mm'), moment(worker.to, ISO_8601).format('HH:mm'));
                if (hours) {
                    worker.hoursHumanized = hours;
                }
            }
            selectedRow.workers.splice(key, 1, worker);
            updateSelectedRow({
                ...selectedRow,
                workers: [...selectedRow.workers]
            });
        }
    }

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        loadOrder();
    }, []);

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    useEffect(() => {
        fetchPricingRates();
    }, [props.orderId]);

    const handleOpen = () => {
        updateSelectedRow(props.row);
        updateShow(props.isOpen);
    };

    const validateRow = () => {
        if (selectedRow && props.order) {
            const workingDayEnd = props.order.working_day_end ? props.order.working_day_end : '0600';

            for (let worker of selectedRow.workers) {
                if (worker.from && worker.to) {
                    let from = moment(worker.from, ISO_8601).format('HHmm');
                    let to = moment(worker.to, ISO_8601).format('HHmm');

                    if (from > to) {
                        if (to > workingDayEnd) {
                            setToast(t("reportPage.validation.workerHoursPassedShift"));
                            setShowToast(true);

                            return false;
                        }
                    } else if (from < to) {
                        if (from < workingDayEnd && to > workingDayEnd) {
                            setToast(t("reportPage.validation.workerHoursPassedShift"));
                            setShowToast(true);

                            return false;
                        }
                    }
                }
            }
        }

        return true;
    }

    const handleSave = () => {
        if (selectedRow) {
            if (validateRow()) {
                props.onSubmit(selectedRow)
            }
        }
    }

    return (
        <StyledIonModal
            isOpen={show}
            onDidDismiss={() => {
                handleClose()
            }}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.99]}
        >
            <StyledModalContent className="modal-wrap">
                <img src={CloseImage} className="close" onClick={() => handleClose()}/>
                <StyledContent className="content">
                    {selectedRow && selectedRow.workers.map(((worker, i) => <StyledSelectedWorkerExtended key={i}>
                            <IonGrid className="ion-no-padding">
                                <IonRow className="user-details">
                                    <IonCol>
                                        <div className="profile-avatar">
                                            <img src={worker?.avatarUrl || AvatarImage} className="avatar"/>
                                        </div>

                                        <br/>

                                        <IonLabel>
                                            {worker.name}
                                        </IonLabel>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol className="title">{t('choosePersonExtended.workerRate')}</IonCol>
                                </IonRow>
                                <IonCol className="select worker-choose-person-worker-rates" onClick={async () => {
                                    updateSelectedWorker(worker);
                                    await fetchWorkerRates(worker);
                                    updateShowWorkerRates(true);
                                }}>
                                    <StyledInput readOnly={true} value={worker.rate?.name}/>
                                    <img className="down" src={ArrowDownImage}/>
                                </IonCol>

                                    <IonRow>
                                        <IonCol className="title">{t('choosePersonExtended.dates')}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="4.5" className="worker-choose-person-time-start">
                                            <DateInput
                                                popover={true}
                                                keepContentsMounted={true}
                                                presentation="time"
                                                format="HH:mm"
                                                cancelText={t("common.dateCancel")}
                                                doneText={t("common.dateDone")}
                                                placeholder={t("common.select")}
                                                onChange={value => onStartDateChange(worker, i, value)}
                                                value={worker.from}
                                                minuteValues={getReportTimepickerMinutesIterations(props.order)}
                                            />
                                        </IonCol>
                                        <IonCol className="arrow" size="1"><img src={ArrowRightImage}/></IonCol>
                                        <IonCol size="4.5" className="worker-choose-person-time-end">
                                            <DateInput
                                                popover={true}
                                                keepContentsMounted={true}
                                                presentation="time"
                                                format="HH:mm"
                                                cancelText={t("common.dateCancel")}
                                                doneText={t("common.dateDone")}
                                                placeholder={t("common.select")}
                                                onChange={value => onEndDateChange(worker, i, value)}
                                                value={worker.to}
                                                minuteValues={getReportTimepickerMinutesIterations(props.order)}
                                                onWillPresent={(e) => {
                                                    if (!worker.to) {
                                                        onEndDateChange(worker, i, getReportNextMoment(props.order).format())
                                                    }
                                                }}
                                            />
                                        </IonCol>
                                        <IonCol className="arrow" size="2">
                                            <small>{worker.hoursHumanized}</small>
                                        </IonCol>
                                    </IonRow>

                                <IonRow>
                                    <IonCol size="12">
                                        <IonItem lines="none">
                                            <StyledIonLabel
                                                className="ion-text-wrap">{t('choosePersonExtended.workerOvertime')}</StyledIonLabel>
                                            <StyledIonCheckbox name="workerOvertime" slot="start"
                                                               checked={worker.workerOvertime}
                                                               onIonChange={(e) => {
                                                                   worker.workerOvertime = e.detail.checked;
                                                               }}
                                                               className="worker-choose-person-worker-overtime"/>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol className="title">{t('choosePersonExtended.pricingRate')}</IonCol>
                                </IonRow>
                                {
                                    order && [PricingType.FIXED_PRICE, PricingType.FIXED_PRICE_PER_MONTH].includes(order.pricing_type)
                                        ? <IonCol className="select">
                                            <StyledInput readOnly={true}
                                                         disabled={true}
                                                         value={order.pricing_type === PricingType.FIXED_PRICE ? t('choosePersonExtended.pricingType.fixedPrice').toString() : t('choosePersonExtended.pricingType.fixedPricePerMonth').toString()}
                                            />
                                            <img className="down" src={ArrowDownImage}/>
                                        </IonCol>
                                        : <IonCol className="select worker-choose-person-pricing-rates"
                                                  onClick={() => {
                                                      updateSelectedWorker(worker);
                                                      updateShowPricingRates(true);
                                                  }}
                                        >
                                            <StyledInput readOnly={true}
                                                         value={worker.pricingRate?.name ? t(worker.pricingRate?.name).toString() : ''}/>
                                            <img className="down" src={ArrowDownImage}/>
                                        </IonCol>
                                }
                                {
                                    order && (!order.is_hide_overtime || worker.workerOvertime) &&
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonItem lines="none">
                                                <StyledIonLabel
                                                    className="ion-text-wrap worker-choose-person-client-overtime">{t('choosePersonExtended.clientOvertime')}</StyledIonLabel>
                                                <StyledIonCheckbox name="clientOvertime" slot="start"
                                                                   checked={worker.clientOvertime}
                                                                   onIonChange={(e) => {
                                                                       worker.clientOvertime = e.detail.checked;
                                                                   }}/>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                }
                            </IonGrid>
                        </StyledSelectedWorkerExtended>
                    ))}
                </StyledContent>
                <StyledFooter className="modal footer no-shadow">
                    <StyledButton onClick={() => handleSave()}>{t('choosePersonExtended.updateWorkers')}</StyledButton>
                </StyledFooter>
            </StyledModalContent>

            {selectedWorker && pricingRates && <ListModal
                list={pricingRates}
                translate={true}
                isOpen={showPricingRates}
                onClose={() => {
                    updateShowPricingRates(false)
                    updateSelectedWorker(null)
                }}
                selected={selectedWorker?.pricingRate?.id}
                onSubmit={(data) => {
                    updateSelectedPricingRate(selectedWorker, data);
                }}
            />}
            {selectedWorker && workerRates && <ListModal
                list={workerRates}
                isOpen={showWorkerRates}
                onClose={() => {
                    updateShowWorkerRates(false)
                    updateSelectedWorker(null)
                }}
                selected={selectedWorker?.rate?.id}
                onSubmit={(data) => {
                    updateSelectedWorkerRate(selectedWorker, data);
                }}
            />}

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {
                    setShowToast(false);
                    setToast('');
                }}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
        </StyledIonModal>
    );
};

export default MultipleExtendedWorkerModal;
import React from 'react';
import {
    StyledWorkerDataChangeButton,
    StyledWorkerDataChangeContainer,
    WorkerDataChangeProps
} from './workerDataChange.style';
import ClockImage from '../../assets/images/time-clock.svg';
import WarningImage from '../../assets/images/warning-red.svg';
import CheckMarkImage from '../../assets/images/checkMarkGreen.svg';
import RemoveImage from '../../assets/images/e-remove-red.svg';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {StyledLightBlueButton} from '../button/button.style';
import {IMyDataChange} from "../../models/myData";
import {WorkerDataChangeStatus} from "../../enums/workerDataChangeStatus";
import {blobToBase64} from "../../services/file.service";
import {Filesystem, FilesystemDirectory} from "@capacitor/filesystem";
import {FileOpener} from "@capacitor-community/file-opener";
import {getWorkerDataChangeStatementPdf} from "../../services/worker.service";

const WorkerDataChange: React.FC<WorkerDataChangeProps> = (props: WorkerDataChangeProps) => {
    const {t} = useTranslation();
    let workerDataChange: IMyDataChange = props.workerDataChange;

    const showBankAccountNumberChangeInfo = (): boolean => {
        let result = false;

        Object.entries(workerDataChange.changes).forEach((change:any) => {
            if (change[0] == 'bankAccountNumber' &&
                moment(workerDataChange.created_at).format('yyyyMM') === moment().format('yyyyMM') &&
                moment().format('D') as unknown as number >= 8) {
                result = true;
            }
        })

        return result;
    };

    const downloadPdf = async (workerDataChange: IMyDataChange) => {
        if (workerDataChange?.id) {
            let fileData = null;
            fileData = await getWorkerDataChangeStatementPdf(workerDataChange?.id)
                .then(response => {
                    return response.data;
                });
            
            let base64 = null;
            if (fileData) {
                await blobToBase64(fileData).then(value => base64 = value);
                if (base64) {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/' + workerDataChange.worker + ' - oswiadczenie o zmianie danych - ' + moment(workerDataChange.created_at).format('YYYY-MM-DD') + '.pdf',
                            data: base64,
                            directory: FilesystemDirectory.Documents,
                            recursive: true
                        })

                        FileOpener.open({
                            'filePath': result.uri,
                            'contentType': 'application/pdf'
                        });
                    } catch(e) {

                    }
                }
            }
        }
    };

    return (
        <StyledWorkerDataChangeContainer>
            <small><img
                src={ClockImage}/><span>{moment(workerDataChange.created_at).format('DD.MM.YYYY')}</span></small>
            {
                Object.entries(workerDataChange.changes).map((value: any, index) => {
                    return <div>
                        <h6>{t("myDataPage.changes.field." + value[0])}:</h6>
                        <span>{value[1][0]} -> {value[1][1]}</span>
                    </div>;
                })
            }
            {
                (workerDataChange.verified === WorkerDataChangeStatus.VERIFIED ||
                workerDataChange.verified === WorkerDataChangeStatus.NOT_VERIFIED ||
                workerDataChange.verified === WorkerDataChangeStatus.REJECTED) &&

                <StyledWorkerDataChangeButton workerDataChange={workerDataChange}>
                    {
                        workerDataChange.verified === WorkerDataChangeStatus.VERIFIED &&
                        <img src={CheckMarkImage}/>
                    }
                    {
                        workerDataChange.verified === WorkerDataChangeStatus.NOT_VERIFIED &&
                        <img src={WarningImage}/>
                    }
                    {
                        workerDataChange.verified === WorkerDataChangeStatus.REJECTED &&
                        <img src={RemoveImage}/>
                    }
                    {
                        workerDataChange.verified === WorkerDataChangeStatus.VERIFIED &&
                        <span>{t("myDataPage.changes.verified")}</span>
                    }
                    {
                        workerDataChange.verified === WorkerDataChangeStatus.NOT_VERIFIED &&
                        <span>{t("myDataPage.changes.not_verified")}</span>
                    }
                    {
                        workerDataChange.verified === WorkerDataChangeStatus.REJECTED &&
                        <span>{t("myDataPage.changes.rejected")}</span>
                    }
                </StyledWorkerDataChangeButton>
            }

            {
                (workerDataChange.verified === WorkerDataChangeStatus.VERIFIED_AUTOMATICALLY &&
                    showBankAccountNumberChangeInfo()) &&

                <StyledWorkerDataChangeButton workerDataChange={workerDataChange}>
                    <img src={WarningImage}/>
                    <span>{t("myDataPage.changes.bank_account_number_change_next_month")}</span>
                </StyledWorkerDataChangeButton>
            }

            {
                (workerDataChange.verified === WorkerDataChangeStatus.VERIFIED ||
                    workerDataChange.verified === WorkerDataChangeStatus.VERIFIED_AUTOMATICALLY) &&
                <StyledLightBlueButton onClick={() => downloadPdf(workerDataChange)}>{t("myDataPage.changes.downloadStatementPdf")}</StyledLightBlueButton>
            }

        </StyledWorkerDataChangeContainer>
    );
};

export default WorkerDataChange;

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {IonItem} from '@ionic/react';
import {StyledIonCheckbox} from '../../../components/form/input/checkbox.style';
import {StyledIonLabel} from '../../../components/form/input/label.style';
import {StyledModalContent} from '../../../components/modal/modal.style';
import LinkLabel from "../../../components/form/input/label.component";
import {Contract} from "../../../models/contract";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type ApprovalsModalProps = {
    isOpen: boolean;
    contract: Contract | undefined;
    onClose: () => void;
    onSign: () => void;
};

const ApprovalsModal: React.FC<ApprovalsModalProps> = (props: ApprovalsModalProps) => {

    const {t} = useTranslation();

    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);
    const [showRegulations, updateShowRegulations] = useState<boolean>(false);
    const [showTisax, updateShowTisax] = useState<boolean>(false);
    const [showForeignTerms, updateShowForeignTerms] = useState<boolean>(false);
    const [showPayrollRegulations, updateShowPayrollRegulations] = useState<boolean>(false);
    const [showFormOfEmploymentInfo, updateShowFormOfEmploymentInfo] = useState<boolean>(false);

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const [rulesAccepted, updateRulesAccepted] = useState<boolean>(false);
    const [disclaimerAccepted, updateDisclaimerAccepted] = useState<boolean>(false);
    const [contractFormAccepted, updateContractFormAccepted] = useState<boolean>(false);
    const [tisaxAccepted, updateTisaxAccepted] = useState<boolean>(false);
    const [foreignTermsAccepted, updateForeignTermsAccepted] = useState<boolean>(false);
    const [foreignerStayUpdateAccepted, updateForeignerStayUpdateAccepted] = useState<boolean>(false);
    const [foreignerTaxResidenceAccepted, updateForeignerTaxResidenceAccepted] = useState<boolean>(false);
    const [foreignerKnowPolishAccepted, updateForeignerKnowPolishAccepted] = useState<boolean>(false);
    const [payrollRegulationsAccepted, updatePayrollRegulationsAccepted] = useState<boolean>(false);
    const [formOfEmploymentInfoAccepted, updateFormOfEmploymentInfoAccepted] = useState<boolean>(false);

    const handleCloseRemarks = () => {
        updateShowRegulations(false);
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
        if (props.isOpen !== showAddRemarks) {
            updateLinesToShow([2, 2, 2]);
        }
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowAddRemarks(props.isOpen);
    };

    const onSubmit = () => {
        props.onSign();
    };

    const [linesToShow, updateLinesToShow] = useState([2, 2, 2]);
    const readMore = (index: number) => {
        const newLinesConfiguration = [...linesToShow];
        newLinesConfiguration[index] = 1000;
        updateLinesToShow(newLinesConfiguration);
    }

    return (
        <>
            <Modal isOpen={showRegulations} onDidDismiss={() => updateShowRegulations(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.regulationModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.regulations_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>
            <Modal isOpen={showTisax} onDidDismiss={() => updateShowTisax(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.tisaxModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.tisax_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>
            <Modal isOpen={showForeignTerms} onDidDismiss={() => updateShowForeignTerms(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.foreignTermsModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.foreign_terms_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>
            <Modal isOpen={showPayrollRegulations} onDidDismiss={() => updateShowPayrollRegulations(false)} canDismiss={false}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.payrollRegulationsModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.payroll_regulations_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>
            <Modal isOpen={showFormOfEmploymentInfo} onDidDismiss={() => updateShowFormOfEmploymentInfo(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.formOfEmploymentInfoModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.form_of_employment_info_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>
            <Modal
                isOpen={showAddRemarks}
                canDismiss={disableSwipeToClose}
                onDidDismiss={() => handleCloseRemarks()}>
                <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                    <StyledModalContent>
                        <h1 className="title">{t("contractPage.approvals.title")}</h1>

                            <div className="content">
                                <LinkLabel urlString={t("contractPage.approvals.checkboxes.rulesAcceptedLink")} url={'#'} onClick={() => updateShowRegulations(true)}>
                                    <IonItem lines="none">
                                        {
                                            !props.contract?.is_ap && <StyledIonLabel className="ion-text-wrap">{ props.contract?.company_code === 'AASPL' || props.contract?.company_code === 'AAS_PL' ? t('contractPage.approvals.checkboxes.rulesAcceptedAAS') : t('contractPage.approvals.checkboxes.rulesAccepted')}</StyledIonLabel>
                                        }
                                        {
                                            props.contract?.is_ap && <StyledIonLabel className="ion-text-wrap">{ t('contractPage.approvals.checkboxes.ap.rulesAcceptedAAS')}</StyledIonLabel>
                                        }
                                        <StyledIonCheckbox name="rulesAccepted" slot="start" onIonChange={(e) => {
                                            updateRulesAccepted(e.detail.checked);
                                        }}/>
                                    </IonItem>
                                </LinkLabel>

                                {(props.contract?.form_type_code && !['UK_FORM_1', 'UK_FORM_2', 'PL_FORM_1', 'AAS_AP_UZ'].includes(props.contract?.form_type_code)) && !props.contract?.is_ap &&
                                    <LinkLabel
                                        urlString={t("contractPage.approvals.checkboxes.foreignTermsAcceptedLink")}
                                        url={'#'} onClick={() => updateShowForeignTerms(true)}>
                                        <IonItem lines="none">
                                            <StyledIonLabel
                                                className="ion-text-wrap">{t('contractPage.approvals.checkboxes.foreignTermsAccepted')}</StyledIonLabel>
                                            <StyledIonCheckbox name="foreignTermsAccepted" slot="start"
                                                               onIonChange={(e) => {
                                                                   updateForeignTermsAccepted(e.detail.checked);
                                                               }}/>
                                        </IonItem>
                                    </LinkLabel>
                                }

                                <LinkLabel urlString={t("contractPage.approvals.checkboxes.disclaimerAcceptedLink")} url={props.contract?.sign_disclaimer_link ?? '#'} external={true}>
                                    <IonItem lines="none">
                                        <StyledIonLabel className="ion-text-wrap">{props.contract?.is_ap ? t('contractPage.approvals.checkboxes.ap.disclaimerAcceptedAAS') : t('contractPage.approvals.checkboxes.disclaimerAccepted')}</StyledIonLabel>
                                        <StyledIonCheckbox name="disclaimerAccepted" slot="start" onIonChange={(e) => {
                                            updateDisclaimerAccepted(e.detail.checked);
                                        }}/>
                                    </IonItem>
                                </LinkLabel>

                                {!props.contract?.is_ap &&
                                    <IonItem lines="none">
                                        <StyledIonLabel className="ion-text-wrap">{t('contractPage.approvals.checkboxes.contractFormAccepted')}</StyledIonLabel>
                                        <StyledIonCheckbox name="contractFormAccepted" slot="start" onIonChange={(e) => {
                                            updateContractFormAccepted(e.detail.checked);
                                        }}/>
                                    </IonItem>
                                }

                                {props.contract?.is_ap &&
                                    <LinkLabel
                                        urlString={t("contractPage.approvals.checkboxes.payrollRegulationsAcceptedLink")}
                                        url={'#'} onClick={() => updateShowPayrollRegulations(true)}>
                                        <IonItem lines="none">
                                            <StyledIonLabel
                                                className="ion-text-wrap">{t('contractPage.approvals.checkboxes.payrollRegulationsAccepted')}</StyledIonLabel>
                                            <StyledIonCheckbox name="payrollRegulationsAccepted" slot="start"
                                                               onIonChange={(e) => {
                                                                   updatePayrollRegulationsAccepted(e.detail.checked);
                                                               }}/>
                                        </IonItem>
                                    </LinkLabel>
                                }

                                {!props.contract?.is_ap && <LinkLabel urlString={t("contractPage.approvals.checkboxes.tisaxAcceptedLink")} url={'#'} onClick={() => updateShowTisax(true)}>
                                        <IonItem lines="none">
                                            <StyledIonLabel className="ion-text-wrap">{t('contractPage.approvals.checkboxes.tisaxAccepted')}</StyledIonLabel>
                                            <StyledIonCheckbox name="tisaxAccepted" slot="start" onIonChange={(e) => {
                                                updateTisaxAccepted(e.detail.checked);
                                            }}/>
                                        </IonItem>
                                    </LinkLabel>
                                }

                                {props.contract?.foreigner_with_polish_language &&
                                    <>
                                        <IonItem lines="none">
                                            <StyledIonLabel className="ion-text-wrap">{t('contractPage.approvals.checkboxes.foreignerStayUpdateAccepted.' + (props.contract?.company_code !== 'AASPL' ? 'exact' : 'aas') )}</StyledIonLabel>
                                            <StyledIonCheckbox name="foreignerStayUpdateAccepted" slot="start" onIonChange={(e) => {
                                                updateForeignerStayUpdateAccepted(e.detail.checked);
                                            }}/>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <StyledIonLabel className="ion-text-wrap">{t('contractPage.approvals.checkboxes.foreignerTaxResidenceAccepted' )}</StyledIonLabel>
                                            <StyledIonCheckbox name="foreignerTaxResidenceAccepted" slot="start" onIonChange={(e) => {
                                                updateForeignerTaxResidenceAccepted(e.detail.checked);
                                            }}/>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <StyledIonLabel className="ion-text-wrap">{t('contractPage.approvals.checkboxes.foreignerTaxResidenceAcceptedDisclaimer' )}</StyledIonLabel>
                                        </IonItem>
                                        {props.contract?.form_type_code !== 'UK_FORM_1' && props.contract?.form_type_code !== 'UK_FORM_2' &&
                                            <IonItem lines="none">
                                                <StyledIonLabel className="ion-text-wrap">{t('contractPage.approvals.checkboxes.foreignerKnowPolishAccepted' )}</StyledIonLabel>
                                                <StyledIonCheckbox name="foreignerKnowPolishAccepted" slot="start" onIonChange={(e) => {
                                                    updateForeignerKnowPolishAccepted(e.detail.checked);
                                                }}/>
                                            </IonItem>
                                        }
                                    </>
                                }

                                {props.contract?.form_of_employment_info_content && props.contract?.form_of_employment_info_content.length > 0 &&
                                    <LinkLabel
                                        urlString={t("contractPage.approvals.checkboxes.formOfEmploymentInfoAcceptedLink")}
                                        url={'#'} onClick={() => updateShowFormOfEmploymentInfo(true)}>
                                        <IonItem lines="none">
                                            <StyledIonLabel
                                                className="ion-text-wrap">{t('contractPage.approvals.checkboxes.formOfEmploymentInfoAccepted')}</StyledIonLabel>
                                            <StyledIonCheckbox name="formOfEmploymentInfoAccepted" slot="start"
                                                               onIonChange={(e) => {
                                                                   updateFormOfEmploymentInfoAccepted(e.detail.checked);
                                                               }}/>
                                        </IonItem>
                                    </LinkLabel>
                                }
                            </div>
                            <StyledFooter className="modal footer no-shadow">
                                <Form.Button type="submit"
                                             disabled={!rulesAccepted
                                                 || !disclaimerAccepted
                                                 || (!props.contract?.is_ap && !contractFormAccepted)
                                                 || ((props.contract?.form_type_code && !['UK_FORM_1', 'UK_FORM_2', 'AAS_AP', 'PL_FORM_1', 'AAS_AP_UZ'].includes(props.contract?.form_type_code)) && !foreignTermsAccepted)
                                                 || (!props.contract?.is_ap && !tisaxAccepted)
                                                 || (props.contract?.is_ap && !payrollRegulationsAccepted)
                                                 || (props.contract?.foreigner_with_polish_language && (!foreignerStayUpdateAccepted || !foreignerTaxResidenceAccepted || (props.contract?.form_type_code !== 'UK_FORM_1' && props.contract?.form_type_code !== 'UK_FORM_2' && props.contract?.form_type_code !== 'AAS_AP' && !foreignerKnowPolishAccepted)))
                                                 || (props.contract?.form_of_employment_info_content != undefined && props.contract?.form_of_employment_info_content.length > 0 && !formOfEmploymentInfoAccepted)}>
                                    {t("contractPage.approvals.acceptButton")}
                                </Form.Button>
                            </StyledFooter>
                    </StyledModalContent>
                </Form.Container>
            </Modal>
        </>
    );
};

export default ApprovalsModal;
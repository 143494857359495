import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    IonAlert,
    IonButtons,
    IonCol,
    IonGrid,
    IonHeader,
    IonPage, IonRow, isPlatform,
    useIonViewWillEnter,
    useIonViewWillLeave
} from '@ionic/react';
import {
    StyledEmptyContainer,
    StyledIonContentCameraPreview,
    StyledIonTitle,
    StyledIonToolbar
} from '../../components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CameraPreviewImage from '../../assets/images/takePhoto-small.png';
import {useTranslation} from 'react-i18next';
import {BarcodeScanner, SupportedFormat} from "@capacitor-community/barcode-scanner"
import {StyledContent} from '../takePhotoPage/takePhotoPage.style';
import CircleImage, {ImageTypes} from "../../components/circleImage/circleImage.component";
import {Links} from "../links";
import {checkSpecification, findOrderById} from "../../services/report.service";
import {Order} from "./reportPage.component";

const QRCodePage: React.FC = () => {

    const {t} = useTranslation();
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [showError, setShowError] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    useEffect(() => {
        updateHeight();
    })

    const startScan = async () => {
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result

        if (result.hasContent) {
            let isOrder = result.content?.includes('order_');
            let error = await handleScan(result.content?.replace('order_', ''), isOrder);
            if (typeof error == 'string') {
                if (error == 'SPEC_NO_NEWEST') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.spec_no_newest'))
                } else if (error == 'SPEC_NO_ACCEPTED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.spec_no_accepted'))
                } else if (error == 'ORDER_TYPE_NOT_ALLOWED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.order_type_not_allowed'))
                } else if (error == 'ORDER_NOT_FOUND') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.order_not_found'))
                } else if (error == 'ORDER_BLOCKED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.order_blocked'))
                } else if (error == 'ORDER_CLOSED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.order_closed'))
                } else if (error == 'WORKER_NO_ALLOWED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.worker_no_allowed'))
                } else if (error == 'WORKER_REPORTING_BLOCKED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.worker_reporting_blocked'))
                } else if (error == 'CLIENT_REPORTING_NOT_ENABLE') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.client_reporting_not_enable'))
                } else if (error == 'ORDER_LIMITS_EXCEEDED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.order_limits_exceeded'))
                } else if (error == 'ORDER_APT_NOT_ALLOWED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.order_apt_not_allowed'))
                } else if (error == 'ORDER_BLOCKED_BY_STATUS') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.order_blocked_by_status'))
                } else if (error == 'WORKER_NOT_ENABLED') {
                    setError(t('reportsTab.orders.scanSpecificationPage.error.worker_not_enabled'))
                } else {
                    if (isOrder) {
                        setError(t('reportsTab.orders.manualOrderFind.error.common'))
                    } else {
                        setError(t('reportsTab.orders.scanSpecificationPage.error.common'))
                    }
                }

                stopScan()
                setShowError(true);
            }
        }
    };

    const handleScan = async (val: any, isOrder: boolean = false) => {
        if (isOrder) {
            let orderData:any = await findOrderById(val)
                .then(response => {
                    if (response.status === 200) {
                        return response.data as Order
                    } else {
                        return 'COMMON';
                    }
                }).catch(reason => {
                    try {
                        let message = reason.response.data?.message;
                        return message !== undefined ? message : 'COMMON';
                    } catch (e) {
                        return 'COMMON';
                    }
                });

            if (typeof orderData == 'string') {
                return orderData;
            }

            if (orderData) {
                history.push(`${Links.report}/${orderData.id}/0/0/0/0`);
            }
        } else {
            let orderData:any = await checkSpecification(val)
                .then(response => {
                    if (response.status === 200) {
                        return {
                            orderId: response.data.id,
                            orderNumber: response.data.formatted_number,
                            orderType: response.data.sub_type,
                            logistic_container: response.data.logistic_container
                        };
                    } else {
                        return 'COMMON';
                    }
                }).catch(reason => {
                    try {
                        let message = reason.response.data?.message;
                        return message !== undefined ? message : 'COMMON';
                    } catch (e) {
                        return 'COMMON';
                    }
                });

            if (typeof orderData == 'string') {
                return orderData;
            }

            if (orderData.logistic_container) {
                history.push(`${Links.qrCodeContainer}/${orderData.orderId}/${val}`);
            } else {
                history.push(`${Links.report}/${orderData.orderId}/${val}/0/0/0`);
            }
        }
    }

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({force: false});

        if (status.granted) {
            // the user granted permission
            return true;
        }

        if (status.denied) {
            // user denied permission
            return false;
        }

        if (status.restricted || status.unknown) {
            // ios only
            // probably means the permission has been denied
            return false;
        }

        if (status.neverAsked) {
            const statusRequest = await BarcodeScanner.checkPermission({force: true});
            if (statusRequest.granted) {
                // the user did grant the permission now
                return true;
            }
        }

        return false;
    }

    const start = async () => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan();
        setShowError(false);
        setError('');
    }

    useIonViewWillEnter(() => {
        if (isPlatform('ios') || isPlatform('android')) {
            start();
        }
    });

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    useIonViewWillLeave(() => {
        if (isPlatform('ios') || isPlatform('android')) {
            stopScan();
        }
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    return (
        <IonPage>
            <StyledIonContentCameraPreview>
                <img className="background" src={CameraPreviewImage}/>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t('reportsTab.orders.scanSpecificationPage.title')}</StyledIonTitle>
                    </StyledIonToolbar>
                    <div className="sub-title-light">{t('reportsTab.orders.scanSpecificationPage.subtitle')}</div>
                </IonHeader>
                <StyledContent>
                    <div className="target">
                        {
                            showError &&
                            <StyledEmptyContainer className="preview">
                                <CircleImage onClick={async (e) => {
                                    start();
                                }} image={ImageTypes.QR} color="red" />
                            </StyledEmptyContainer>
                        }
                    </div>
                    <div className={'action-buttons empty multiple-buttons'}>
                        <IonGrid>
                            {showError && <IonRow>
                                <IonCol size="12">
                                    <p className="errorMessage">{error}</p>
                                </IonCol>
                            </IonRow>}
                            <IonRow>
                                <IonCol size="6"><StyledButton id="reports-scan-page-manual" onClick={() => history.push(Links.manualBarcode)}>{t('reportsTab.orders.scanSpecificationPage.manualButton')}</StyledButton></IonCol>
                                <IonCol size="6"><StyledButton id="reports-scan-page-find" onClick={() => history.push(Links.manualOrderFind)}>{t('manualOrderFind.find_button')}</StyledButton></IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </StyledContent>
            </StyledIonContentCameraPreview>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />
        </IonPage>
    );
};

export default QRCodePage;

import React, {useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {
    IonButtons, IonCol, IonGrid,
    IonHeader, IonItem, IonLabel,
    IonPage, IonRow, IonSearchbar, IonToast,
} from '@ionic/react';
import {
    StyledDataTable,
    StyledIonContent,
    StyledIonTitle,
    StyledIonToolbar
} from '../../components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import CheckGrayImage from '../../assets/images/check-gray.svg';
import AvatarImage from '../../assets/images/logo/x-black.png';
import {StyledContent, StyledSelectedWorkerExtended} from './choosePersonPage.style';
import StaticPane from '../../components/pane/static-pane.component';
import {useTranslation} from 'react-i18next';
import {getWorkerConfiguration} from '../../services/qrCodePage.service';
import {ReportWorker} from "../../models/raport";
import {
    checkSpecification,
    findOrderById,
    getPriceRates,
    getReportNextMoment,
    getReportTimepickerMinutesIterations,
    getWorkerRates,
    orderWorkers
} from "../../services/report.service";
import {DataSelectable} from "./components/report/reportRowForm.component";
import ListModal from "./modals/listModal.component";
import {Order, ReportRowWorker} from "./reportPage.component";
import {StyledInput} from "../../components/form/input/input.style";
import ArrowDownImage from "../../assets/images/down-arrow.svg";
import ArrowRightImage from "../../assets/images/arrow-right.svg";
import {StyledIonLabel} from "../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../components/form/input/checkbox.style";
import moment from "moment";
import LoadingSkeleton from "../../components/loading/loadingSkeleton.component";
import {useIsInViewport} from "../../utils/tools/viewport";
import DateInput from "../../components/form/input/date.component";
import {PricingType} from "../../enums/pricing";

interface RouterProps {
    orderId: string;
    reportId: string;
    specificationId: string;
    serviceDate: string;
}

interface ChoosePersonProps extends RouteComponentProps<RouterProps> {
}

const ChoosePersonExtendedPage: React.FC<ChoosePersonProps> = ({match}) => {
    const history = useHistory();
    const {t} = useTranslation();
    const [workers, updateWorkers] = useState<ReportWorker[]>();
    const [selectedWorker, updateSelectedWorker] = useState<ReportRowWorker | null>(null);
    const [pricingRates, updatePricingRates] = useState<DataSelectable[]>();
    const [showPricingRates, updateShowPricingRates] = useState<boolean>(false);
    const [workerRates, updateWorkerRates] = useState<DataSelectable[]>();
    const [showWorkerRates, updateShowWorkerRates] = useState<boolean>(false);
    const [toast, setToast] = useState<any>('');
    const [showToast, setShowToast] = useState(false);
    const [order, setOrder] = useState<Order | null>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string | undefined>();
    const loadMoreRef = useRef(null);
    const loadMoreIsInViewport = useIsInViewport(loadMoreRef);
    const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);

    const {params: {orderId, reportId, specificationId, serviceDate}} = match;

    const configuration = getWorkerConfiguration();

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const addSelectedWorker = () => {
        if (configuration?.handleExtendedSelected && selectedWorker) {
            configuration?.handleExtendedSelected(selectedWorker);
        }
    }

    const selectWorker = async (worker: ReportWorker) => {
        updateSelectedWorker({
            personId: worker.id.toString(),
            name: worker.name,
            number: worker.number,
            avatarUrl: worker.user ? (worker.user.photos?.thumbnail ? worker.user.photos.thumbnail : worker.user.avatar) : null,
            rate: worker.defaultRate,
            from: getReportNextMoment(order).format(),
            to: getReportNextMoment(order).format(),
        });
    };

    const updateSelectedPricingRate = (pricingRate: DataSelectable) => {
        if (selectedWorker) {
            selectedWorker.pricingRate = pricingRate;
        }
        updateShowPricingRates(false);
    }

    const updateSelectedWorkerRate = (workerRate: DataSelectable) => {
        if (selectedWorker) {
            selectedWorker.rate = workerRate;
        }
        updateShowWorkerRates(false);
    }

    const validateWorker = () => {
        if (selectedWorker && order) {
            const workingDayEnd = order.working_day_end ? order.working_day_end : '0600';

            if (selectedWorker.from && selectedWorker.to) {
                let from = moment(selectedWorker.from, moment.ISO_8601).format('HHmm');
                let to = moment(selectedWorker.to, moment.ISO_8601).format('HHmm');
                if (from > to) {
                    if (to > workingDayEnd) {
                        setToast(t("reportPage.validation.workerHoursPassedShift"));
                        setShowToast(true);

                        return false;
                    }
                } else if (from < to) {
                    if (from < workingDayEnd && to > workingDayEnd) {
                        setToast(t("reportPage.validation.workerHoursPassedShift"));
                        setShowToast(true);

                        return false;
                    }
                }
            }
        }

        return true;
    }

    const handleSave = () => {
        if (validateWorker()) {
            addSelectedWorker();
        }
    };

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    if (!configuration) {
        history.goBack();
    }

    const fetchPricingRates = async () => {
        if (pricingRates === undefined) {
            updatePricingRates([]);
            await getPriceRates(orderId, reportId, serviceDate)
                .then(response => {
                    updatePricingRates(response.data);
                });
        }
    }

    const fetchWorkerRates = async () => {
        if (selectedWorker && selectedWorker.number) {
            await getWorkerRates(orderId, selectedWorker.number, serviceDate)
                .then(response => {
                    updateWorkerRates(response.data);
                });
        } else {
            updateWorkerRates(undefined);
        }
    }

    const onStartDateChange = (value: string) => {
        if (selectedWorker) {
            selectedWorker.from = value;

            updateSelectedWorker({
                ...selectedWorker,
                from: selectedWorker.from
            })
        }
    };

    const onEndDateChange = (value: string) => {
        if (selectedWorker) {
            selectedWorker.to = value;

            updateSelectedWorker({
                ...selectedWorker,
                to: selectedWorker.to
            })
        }
    };

    useEffect(() => {
        fetchWorkers();
        fetchPricingRates();
        loadOrder();
        updateSelectedWorker(null);
    }, []);

    const loadOrder = async () => {
        if (order === undefined) {
            if (specificationId != '0') {
                await checkSpecification(specificationId, reportId, true).then((response) => {
                    let order = response.data as Order;
                    setOrder(order);
                })
            } else {
                await findOrderById(orderId, true).then((response) => {
                    let order = response.data as Order;
                    setOrder(order);
                })
            }
        }
    }

    useEffect(() => {
        fetchWorkerRates();
    }, [selectedWorker]);

    const fetchWorkers = async (forceReload: boolean = false, append: boolean = false, page: number = 1) => {
        if (!append) {
            setLoading(true)
        } else {
            setReloading(true);
        }
        if (workers === undefined || forceReload) {
            if (!append) {
                updateWorkers([]);
            }
            await orderWorkers(orderId, serviceDate, page, searchText)
                .then(response => {
                    if (append) {
                        if (workers) {
                            let newData = response.data;
                            if (newData && newData.length == 0) {
                                setLoadMoreDisabled(true);
                            } else {
                                setLoadMoreDisabled(false);
                            }
                            updateWorkers([...workers, ...newData]);
                        }
                        setReloading(false);
                    } else {
                        setLoadMoreDisabled(false);
                        updateWorkers(response.data);
                    }

                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                    setReloading(false)
                });
        }
    }

    useEffect(() => {
        setPage(1);
        if (searchText !== undefined) {
            fetchWorkers(true, false, 1);
        }
    }, [searchText])

    const loadMoreWorkers = async () => {
        let tmp = page + 1;
        setPage(page + 1);
        fetchWorkers(true, true, tmp);
    };

    useEffect(() => {
        if (workers && workers.length > 0 && loadMoreIsInViewport && !loadMoreDisabled) {
            loadMoreWorkers();
        }
    }, [loadMoreIsInViewport]);

    const canSave = (): boolean => {
        return !!(selectedWorker && selectedWorker.rate && selectedWorker.to && selectedWorker.from && (order && [PricingType.FIXED_PRICE, PricingType.FIXED_PRICE_PER_MONTH].includes(order.pricing_type) ? true : selectedWorker.pricingRate));
    }

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle className="wrap-text">
                            <div className="wrap-text">
                                {t('choosePersonExtended.title')}
                            </div>
                        </StyledIonTitle>
                        {selectedWorker &&
                            <IonButtons slot="end">
                                <StyledHeaderButton
                                    disabled={!canSave()}
                                    onClick={() => handleSave()}>
                                    <StyledHeaderButtonImage
                                        src={canSave() ? CheckImage : CheckGrayImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                        }
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    {!selectedWorker &&
                        <StyledContent>
                            <IonSearchbar
                                debounce={500}
                                onIonChange={e => setSearchText(e.detail.value!)}
                                onIonInput={e => setSearchText(e.detail.value!)}
                                placeholder={t('choosePersonExtended.search')}
                            />
                            <div className="content full-height">
                                {loading && <LoadingSkeleton includeAvatar={true}/>}
                                {!loading && <StyledDataTable className="no-margin">
                                    {workers && workers.map((data, i) => <div key={i} className="data-row"
                                                                              onClick={() => selectWorker(data)}>
                                        <div className="data-icon-title">
                                            <img className="avatar"
                                                 src={data.user && (data.user?.photos || data.user?.avatar) ? (data.user.photos?.thumbnail ? data.user.photos.thumbnail : data.user.avatar) : AvatarImage}/>
                                            {data.name}
                                        </div>
                                    </div>)
                                    }
                                </StyledDataTable>}

                                {reloading && <LoadingSkeleton includeAvatar={true}/>}
                                <div ref={loadMoreRef}/>
                            </div>
                        </StyledContent>
                    }

                    {selectedWorker &&
                        <StyledSelectedWorkerExtended>
                            <IonGrid className="ion-no-padding">
                                <IonRow className="user-details">
                                    <IonCol>
                                        <div className="profile-avatar">
                                            <img src={selectedWorker?.avatarUrl || AvatarImage} className="avatar"/>
                                        </div>

                                        <br/>

                                        <IonLabel>
                                            {selectedWorker.name}
                                        </IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="title">{t('choosePersonExtended.workerRate')}</IonCol>
                                </IonRow>
                                <IonCol className="select worker-choose-person-worker-rates"
                                        onClick={() => {
                                            updateShowWorkerRates(true);
                                        }}
                                >
                                    <StyledInput readOnly={true} value={selectedWorker.rate?.name}/>
                                    <img className="down" src={ArrowDownImage}/>
                                </IonCol>

                                <IonRow>
                                    <IonCol className="title">{t('choosePersonExtended.dates')}</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="5.5" className="worker-choose-person-time-start">
                                        <DateInput
                                            popover={true}
                                            keepContentsMounted={true}
                                            presentation="time"
                                            format="HH:mm"
                                            cancelText={t("common.dateCancel")}
                                            doneText={t("common.dateDone")}
                                            placeholder={t("common.select")}
                                            onChange={e => onStartDateChange(e)}
                                            value={selectedWorker.from}
                                            minuteValues={getReportTimepickerMinutesIterations(order)}
                                        />
                                    </IonCol>
                                    <IonCol className="arrow" size="1"><img src={ArrowRightImage}/></IonCol>
                                    <IonCol size="5.5" className="worker-choose-person-time-end">
                                        <DateInput
                                            popover={true}
                                            keepContentsMounted={true}
                                            presentation="time"
                                            format="HH:mm"
                                            cancelText={t("common.dateCancel")}
                                            doneText={t("common.dateDone")}
                                            placeholder={t("common.select")}
                                            onChange={e => onEndDateChange(e)}
                                            value={selectedWorker.to}
                                            minuteValues={getReportTimepickerMinutesIterations(order)}
                                        />
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol size="12">
                                        <IonItem lines="none">
                                            <StyledIonLabel
                                                className="ion-text-wrap">{t('choosePersonExtended.workerOvertime')}</StyledIonLabel>
                                            <StyledIonCheckbox name="workerOvertime" slot="start" onIonChange={(e) => {
                                                selectedWorker.workerOvertime = e.detail.checked;
                                            }} className="worker-choose-person-worker-overtime"/>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol className="title">{t('choosePersonExtended.pricingRate')}</IonCol>
                                </IonRow>
                                {
                                    order && [PricingType.FIXED_PRICE, PricingType.FIXED_PRICE_PER_MONTH].includes(order.pricing_type)
                                        ? <IonCol className="select">
                                            <StyledInput readOnly={true}
                                                         disabled={true}
                                                         value={order.pricing_type === PricingType.FIXED_PRICE ? t('choosePersonExtended.pricingType.fixedPrice').toString() : t('choosePersonExtended.pricingType.fixedPricePerMonth').toString()}
                                            />
                                            <img className="down" src={ArrowDownImage}/>
                                        </IonCol>
                                        : <IonCol className="select worker-choose-person-pricing-rates"
                                                  onClick={() => {
                                                      updateShowPricingRates(true);
                                                  }}
                                        >
                                            <StyledInput readOnly={true}
                                                         value={selectedWorker.pricingRate?.name ? t(selectedWorker.pricingRate?.name).toString() : ''}
                                            />
                                            <img className="down" src={ArrowDownImage}/>
                                        </IonCol>
                                }
                                {
                                    order && !order.is_hide_overtime &&
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonItem lines="none">
                                                <StyledIonLabel
                                                    className="ion-text-wrap worker-choose-person-client-overtime">{t('choosePersonExtended.clientOvertime')}</StyledIonLabel>
                                                <StyledIonCheckbox name="clientOvertime" slot="start" onIonChange={(e) => {
                                                    selectedWorker.clientOvertime = e.detail.checked;
                                                }}/>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                }
                            </IonGrid>
                            <StyledButton
                                disabled={!canSave()}
                                onClick={() => handleSave()}>{t('choosePersonExtended.addWorker')}</StyledButton>
                        </StyledSelectedWorkerExtended>
                    }
                </StaticPane>
            </StyledIonContent>
            <ListModal list={pricingRates}
                       isOpen={showPricingRates}
                       translate={true}
                       onClose={() => updateShowPricingRates(false)}
                       selected={selectedWorker?.pricingRate?.id}
                       onSubmit={updateSelectedPricingRate}
            />
            <ListModal list={workerRates}
                       isOpen={showWorkerRates}
                       onClose={() => updateShowWorkerRates(false)}
                       selected={selectedWorker?.rate?.id}
                       onSubmit={updateSelectedWorkerRate}
            />
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {
                    setShowToast(false);
                    setToast('');
                }}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
        </IonPage>
    );
};

export default ChoosePersonExtendedPage;

import React, { useEffect, useRef, useState } from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {
    IonButtons,
    IonHeader,
    IonPage, IonSearchbar
} from '@ionic/react';
import { StyledDataTable, StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import AvatarImage from '../../assets/images/logo/x-black.png';
import { useForm } from 'react-hook-form';
import { StyledContent } from './choosePersonPage.style';
import Form from '../../components/form';
import StaticPane from '../../components/pane/static-pane.component';
import { useTranslation } from 'react-i18next';
import { getWorkerConfiguration } from '../../services/qrCodePage.service';
import {ReportWorker} from "../../models/raport";
import {orderWorkers} from "../../services/report.service";
import LoadingSkeleton from "../../components/loading/loadingSkeleton.component";
import {useIsInViewport} from "../../utils/tools/viewport";

interface RouterProps {
    orderId: string;
    serviceDate: string;
}

interface ChoosePersonProps extends RouteComponentProps<RouterProps> {
}

const ChoosePersonPage: React.FC<ChoosePersonProps> = ({match}) => {

    const history = useHistory();
    const { t } = useTranslation();
	const [error, setError] = useState<string>();
    const [workers, updateWorkers] = useState<ReportWorker[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState<string|undefined>();
    const loadMoreRef = useRef(null);
    const loadMoreIsInViewport = useIsInViewport(loadMoreRef);
    const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);

    const {params: {orderId, serviceDate}} = match;

    const configuration = getWorkerConfiguration();

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const { register, formState, getValues, handleSubmit } = useForm({
        mode: 'all'
    });

    const choosePerson = async (personId: string) => {
        if (configuration?.handleScan) {
            let error = await configuration?.handleScan(personId);
            if (error === false) {
                setError(t('reportsTab.orders.scanPersonPage.error'))
            }
        }
    };

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    if (!configuration) {
        history.goBack();
    }

    const fetchWorkers = async (forceReload: boolean = false, append: boolean = false, page: number = 1) => {
        if (!append) {
            setLoading(true)
        } else {
            setReloading(true);
        }
        if (workers === undefined || forceReload) {
            if (!append) {
                updateWorkers([]);
            }
            await orderWorkers(orderId, serviceDate, page, searchText)
                .then(response => {
                    if (append) {
                        if (workers) {
                            let newData = response.data;
                            if (newData && newData.length == 0) {
                                setLoadMoreDisabled(true);
                            } else {
                                setLoadMoreDisabled(false);
                            }
                            updateWorkers([...workers, ...newData]);
                        }
                        setReloading(false);
                    } else {
                        setLoadMoreDisabled(false);
                        updateWorkers(response.data);
                    }

                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                    setReloading(false)
                });
        }
    }

    useEffect(() => {
        setPage(1);
        if (searchText !== undefined) {
            fetchWorkers(true, false, 1);
        }
    }, [searchText])

    const loadMoreWorkers = async () => {
        let tmp = page + 1;
        setPage(page + 1);
        fetchWorkers(true, true, tmp);
    };

    useEffect(() => {
        if (workers && workers.length > 0 && loadMoreIsInViewport && !loadMoreDisabled) {
            loadMoreWorkers();
        }
    }, [loadMoreIsInViewport]);

    useEffect(() => {
        fetchWorkers();
    }, []);

	return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle className="wrap-text">
                            <div className="wrap-text">
                                {t('choosePerson.title')}
                            </div>
                        </StyledIonTitle>
                        {/*<IonButtons slot="end">*/}
                        {/*    <StyledHeaderButton onClick={() => handleSave()}>*/}
                        {/*        <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>*/}
                        {/*    </StyledHeaderButton>*/}
                        {/*</IonButtons>*/}
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <StyledContent>
                        <IonSearchbar
                            debounce={1000}
                            onIonChange={e => setSearchText(e.detail.value ?? '')}
                            onIonInput={e => setSearchText(e.detail.value ?? '')}
                            placeholder={t('choosePerson.search')}
                        />
                        <div className="content full-height">
                            {loading && <LoadingSkeleton includeAvatar={true} />}
                            {!loading && <StyledDataTable className="no-margin">
                                { workers && workers.map((data, i) => <div key={i} className="data-row" onClick={() => choosePerson(data.number)}>
                                    <div className="data-icon-title">
                                        <img className="avatar" src={data.user && (data.user?.photos || data.user?.avatar) ? (data.user.photos?.thumbnail ? data.user.photos.thumbnail : data.user.avatar) : AvatarImage}/>
                                        {data.name} ({data.number})
                                    </div>
                                </div>)
                                }
                            </StyledDataTable>}

                            {reloading && <LoadingSkeleton includeAvatar={true} />}

                            <div ref={loadMoreRef} />
                        </div>
                    </StyledContent>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default ChoosePersonPage;

import {IonRow, IonSkeletonText, useIonViewWillEnter} from "@ionic/react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Profile } from "../../../models/profile";
import { StyledInconsitencyModuleListContent } from "./inconcistencyModule.style";
import {getInconsistencyRecords} from "../../../services/audit.service";
import {getPermission} from "../../../services/permission.service";
import {log} from "../../../services/firebaseAnalytics.service";
import {InconsistencyRecord} from "../../../models/auditReport";
import Pane from "../../../components/pane/pane.component";
import {StyledNoAuditReports} from "../../auditReport/panes/auditReports.style";
import NoOrdersImage from "../../../assets/images/no-orders.svg";
import ReportsImage from "../../../assets/images/chart-pie-36.svg";
import {useHistory} from "react-router-dom";
import {Links} from "../../links";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

export type InconsistencyListItem = {
    date: string;
    name: string;
    place: string;
}

type InconsistencyModuleListPaneProps = {
    topEdge: number | undefined;
    profile?: Profile;
}

const InconsistencyModuleListPane: React.FC<InconsistencyModuleListPaneProps> = (props: InconsistencyModuleListPaneProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [forceReload, setForceReload] = useState<boolean>(false);
    const [editPermission, setEditPermission] = useState<boolean>(false);
    const [records, setRecords] = useState<[]|undefined>(undefined);

    const fetchRecords = async () => {
        if ((records === undefined && !loading) || forceReload) {
            setLoading(true);
            setRecords([]);
            const response = await getInconsistencyRecords()
                .then(response => {
                    setRecords(response.data);
                })
                .catch(reason => {
                    setRecords([]);
                });

            setLoading(false);
        }
    }

    const fetchPermission = async () => {
        await getPermission('QUALITY_DEPARTMENT', 'EDIT')
            .then(response => {
                setEditPermission(response.data);
            })
    }

    const openRecord = (record: InconsistencyRecord) => {
        if (editPermission) {
            history.push(Links.inconsistencyRecordEdit + '/' + record.id)
        } else {
            history.push(Links.inconsistencyRecordPreview + '/' + record.id)
        }
    }

    useEffect(() => {
        fetchPermission();
        fetchRecords();
    }, []);

    useIonViewWillEnter( () => {
        fetchRecords();
    });

    useEffect(() => {
        log('page_visit', {
            page: 'Audyty -> moduł niezgodności'
        });
    }, []);

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            {
                !loading && records && records.length === 0 &&
                <StyledNoAuditReports>
                    <img src={NoOrdersImage}/>
                    <img className="reports" src={ReportsImage}/>
                    <span className="message">{t('inconsistencyTab.noRecords')}</span>
                </StyledNoAuditReports>
            }

            {!loading && records && records.length != 0 && <StyledInconsitencyModuleListContent>
                <IonRow className="details-header">
                    <div className="details-header__month">{t('inconsistencyTab.subTitle')}</div>
                    <hr className="details-header__hr"/>
                </IonRow>

                {records.map((record: InconsistencyRecord, index) => {
                    return (
                        <IonRow className="inconcistency-detail" key={index} onClick={ () => openRecord(record) }>
                            <div className="inconcistency-detail__fs12">{moment(record.audit_report_question.audit_report.date).format('DD.MM.YYYY')}</div>
                            <div className="inconcistency-detail__fs14">{record.audit_report_question.question}</div>
                            <div className="inconcistency-detail__fs12">{t('inconsistency.form.placeOfService')}: {record.audit_report_question.audit_report.place_of_service?.name}</div>
                            <div className="inconcistency-detail__fs12">{t('inconsistency.form.order')}: {record.audit_report_question.audit_report.order?.formatted_number ?? '---'}</div>
                        </IonRow>
                    )
                })}
            </StyledInconsitencyModuleListContent>}

            {
                loading &&
                <StyledInconsitencyModuleListContent>
                    <IonRow className="inconcistency-detail">
                        <div className="inconcistency-detail__fs12"><IonSkeletonText animated style={{ width: '50%' }} /></div>
                        <div className="inconcistency-detail__fs14"><IonSkeletonText animated style={{ width: '30%' }} /></div>
                        <div className="inconcistency-detail__fs12"><IonSkeletonText animated style={{ width: '70%' }} /></div>
                    </IonRow>

                    <IonRow className="inconcistency-detail">
                        <div className="inconcistency-detail__fs12"><IonSkeletonText animated style={{ width: '50%' }} /></div>
                        <div className="inconcistency-detail__fs14"><IonSkeletonText animated style={{ width: '30%' }} /></div>
                        <div className="inconcistency-detail__fs12"><IonSkeletonText animated style={{ width: '70%' }} /></div>
                    </IonRow>

                    <IonRow className="inconcistency-detail">
                        <div className="inconcistency-detail__fs12"><IonSkeletonText animated style={{ width: '50%' }} /></div>
                        <div className="inconcistency-detail__fs14"><IonSkeletonText animated style={{ width: '30%' }} /></div>
                        <div className="inconcistency-detail__fs12"><IonSkeletonText animated style={{ width: '70%' }} /></div>
                    </IonRow>
                </StyledInconsitencyModuleListContent>
            }
        </Pane>
    );
};

export default InconsistencyModuleListPane;

import React, {useEffect, useRef, useState} from 'react';
import {IonAlert, IonCol, IonGrid, IonItem, IonRow, IonSelectOption, IonToast} from "@ionic/react";
import {StyledField, StyledInput} from "../../../components/form/input/input.style";
import {StyledMyDataField} from "../panes/content.style";
import {useForm} from "react-hook-form";
import {IMyDataField, MyDataEditFields, MyDataEditFieldsGroups, MyDataEditType} from "../panes/contentPane.component";
import Form from "../../../components/form";
import {StyledWiteButton} from "../../../components/button/button.style";
import {StyledIonSelect} from "../../../components/form/input/select.style";
import {ContractDataSelectable} from "../../../models/contractData";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {IMyData} from "../../../models/myData";
import SignWithPasswordModal, {
    ISignWithPasswordModalConfigration
} from "../../../modals/sign/signWithPassword.component";
import SignModal, {ISignModalConfigration} from "../../../modals/sign/sign.component";
import {signWorkerData, signWorkerDataVerify} from "../../../services/worker.service";
import ExactPhoneInput from "../../../components/form/input/phoneInput.component";
import Info from "../../../components/info/info.component";
import moment from "moment";
import {StyledIonCheckbox} from "../../../components/form/input/checkbox.style";
import {StyledIonLabel} from "../../../components/form/input/label.style";
import {Preferences} from "@capacitor/preferences";
import CityModal from "../../contractDataPage/modals/cityModal.component";
import DownArrowImage from "../../../assets/images/down-arrow.svg";
import {DataSelectable} from "../../reportPage/components/report/reportRowForm.component";
import {saveReport} from "@services/report.service";
import {Links} from "@app/links";
import {ProfileType} from "@enums/profileType";

type FieldsFormProps = {
    fields: IMyDataField[],
    data?: IMyData,
    refreshData: () => void
}

type SaveActionParams = {
    skipDuplicateBank?: boolean;
}

type SaveAction = {
    func: (...args: any[]) => Promise<void>;
    params: SaveActionParams;
};

interface ICity {
    id: string;
    name: string;
    selected: boolean
}

const MyDataForm: React.FC<FieldsFormProps> = (props: FieldsFormProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [lastNameEditMode, setLastNameEditMode] = useState<boolean>(false);
    const [firstNameEditMode, setFirstNameEditMode] = useState<boolean>(false);
    const [secondNameEditMode, setSecondNameEditMode] = useState<boolean>(false);
    const [familyNameEditMode, setFamilyNameEditMode] = useState<boolean>(false);
    const [addressEditMode, setAddressEditMode] = useState<boolean>(false);
    const [taxOfficeEditMode, setTaxOfficeEditMode] = useState<boolean>(false);
    const [bankAccountNumberEditMode, setBankAccountNumberEditMode] = useState<boolean>(false);
    const [bankAccountEditMode, setBankAccountEditMode] = useState<boolean>(false);
    const [phoneNumberEditMode, setPhoneNumberEditMode] = useState<boolean>(false);
    const [emailEditMode, setEmailEditMode] = useState<boolean>(false);
    const [placeOfBirthEditMode, setPlaceOfBirthEditMode] = useState<boolean>(false);
    const [addressVisible, setAddressVisible] = useState<boolean>(false);
    const [bankAccountVisible, setBankAccountVisible] = useState<boolean>(false);
    const [manualIsDirty, updateManualIsDirty] = useState<boolean>(false);
    const [signModalConfiguration, updateSignModalConfiguration] = useState<ISignModalConfigration>();
    const [showSignModal, updateShowSignModal] = useState<boolean>(false);
    const [showSignWithPasswordModal, updateShowSignWithPasswordModal] = useState<boolean>(false);
    const [signFormSubmitted, updateSignFormSubmitted] = useState(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [phoneNumber, updatePhoneNumber] = useState('');
    const [phoneNumberError, updatePhoneNumberError] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [bicswiftConfirmationChecked, updateBicswiftConfirmationChecked] = useState<boolean>(false);
    const [isFacebookUser, updateIsFacebookUser] = useState<boolean>(false);
    const [showCityModal, updateShowCityModal] = useState<boolean>(false);
    const [city, updateCity] = useState<ICity>();

    const [showPostValidationAlert, setShowPostValidationAlert] = useState<boolean>(false);
    const [postSaveAction, setPostSaveAction] = useState<SaveAction | null>(null);
    const [validationAlertTitle, setValidationAlertTitle] = useState<string>('');

    const closeModalCity = async (city: ICity | undefined) => {
        if (city && props.data?.city.nameFull != city.name) {
            updateManualIsDirty(true);
        }
    };

    const setValues = (): any => {
        let defaultObject: any = {};

        props.fields.forEach((field) => {
            setValue(field.name, field.value);

            if (field.name == MyDataEditFields.PHONE) {
                updatePhoneNumber(field.value);
            }
        });

        return defaultObject;
    };

    useEffect(() => {
        setValues();
        resetForm();
    }, [props.data]);

    const {register, errors, setError, getValues, setValue, handleSubmit, formState, clearErrors} = useForm({
        mode: 'onChange'
    });

    const {isValid, isDirty} = formState;

    const sign = async (data: any) => {
        await signWorkerData(data)
            .then(response => {
                if (response.data.result == "error") {
                    setToast(t("common.serverErrorMsg"));
                    setShowToast(true);
                } else {
                    updateSignModalConfiguration({
                        title: t("myDataPage.sign.title"),
                        info: t("myDataPage.sign.info"),
                        buttonText: t("myDataPage.sign.signButton"),
                    });

                    updateShowSignModal(true);
                }
            })
            .catch(error => {
                handleDuplicateBankError(error, data, sign);
            })
        ;
    };

    const signWithPassword = async (data: any) => {
        await signWorkerData(data)
            .then(response => {
                if (response.data.result == "error") {
                    setToast(t("common.serverErrorMsg"));
                    setShowToast(true);
                } else {
                    updateSignModalConfiguration({
                        title: t("myDataPage.sign.title"),
                        info: t("myDataPage.sign.info"),
                        buttonText: t("myDataPage.sign.signButton"),
                    });

                    updateShowSignWithPasswordModal(true);
                }
            })
            .catch(error => {
                handleDuplicateBankError(error, data, signWithPassword);
            })
        ;
    };

    const signSave = async (code: string, password?: string) => {
        updateSignFormSubmitted(true);
        await signWorkerDataVerify(code, password)
            .then(response => {
                if (response.data?.result) {
                    if (response.data?.result != 'error') {
                        if (response.data?.field == 'lastName') {
                            setShowAlertMessage(t("myDataPage.sign.lastName.message"))
                            setShowAlert(true);
                        } else if (response.data?.field == 'bankAccountNumber' && moment().format('D') as unknown as number >= 8) {
                            setShowAlertMessage(t("myDataPage.sign.bankAccountNumber.message"))
                            setShowAlert(true);
                        } else {
                            props.refreshData();
                        }

                        resetForm();
                    }
                } else {
                    resetForm();
                }
                // props.refreshData();
                updateShowSignModal(false);
                updateShowSignWithPasswordModal(false);
                updateSignFormSubmitted(false);
                updateBicswiftConfirmationChecked(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    if (password) {
                        setToast(t("common.invalidVerifyCodeOrPassword"));
                    } else {
                        setToast(t("common.invalidVerifyCode"));
                    }
                    setShowToast(true);
                }
                updateSignFormSubmitted(false);
            });
    };

    const handleValidationAlertCancel = () => {
        setShowPostValidationAlert(false);
        setPostSaveAction(null);
    };

    const handleDuplicateBankError = async (error: any, data: any, funcName: any) => {
        if (error.response.status === 400 && error.response.data.duplicateBank != undefined) {
            try {
                let message = error.response.data.duplicateBank;
                if (message) {
                    data.skipDuplicateBank = 1;
                    handlePostSaveValidationAlertOpen('myDataPage.bank_account.duplicate_warning', {
                        func: funcName,
                        params: data
                    });
                    return;
                }
            } catch (e) {
            }
            setShowToast(true);
            handleValidationAlertCancel();
        } else if (error.response && error.response.status === 400) {
            setErrors(error.response.data.errors.children);
        }
    };

    const handlePostSaveValidationAlertConfirm = async () => {
        if (postSaveAction) {
            const action = postSaveAction;
            setPostSaveAction(null);
            await action.func(action.params);
            setShowPostValidationAlert(false);
        }
    };

    const handlePostSaveValidationAlertOpen = (alertTitle: string, saveActionCallback: SaveAction) => {
        setShowPostValidationAlert(true);
        setValidationAlertTitle(alertTitle);
        setPostSaveAction(saveActionCallback);
    }

    const onSubmit = async (data: any) => {
        if (isValid) {
            data.skipDuplicateBank = 0;
            if (data.bankAccountNumber != undefined) {
                if (isFacebookUser) {
                    sign(data);
                } else {
                    signWithPassword(data);
                }
            } else {
                sign(data);
            }
        }
    }

    const signPhoneNumber = () => {
        sign({'phone': phoneNumber});
    }

    const changeEditMode = (name: string, edit?: boolean) => {
        clearErrors();

        switch (name) {
            case MyDataEditFieldsGroups.LAST_NAME:
                return setLastNameEditMode(edit !== undefined ? edit : !lastNameEditMode);
                break;
            case MyDataEditFieldsGroups.FIRST_NAME:
                return setFirstNameEditMode(edit !== undefined ? edit : !firstNameEditMode);
                break;
            case MyDataEditFieldsGroups.SECOND_NAME:
                return setSecondNameEditMode(edit !== undefined ? edit : !secondNameEditMode);
                break;
            case MyDataEditFieldsGroups.FAMILY_NAME:
                return setFamilyNameEditMode(edit !== undefined ? edit : !familyNameEditMode);
                break;
            case MyDataEditFieldsGroups.ADDRESS:
                return setAddressEditMode(edit !== undefined ? edit : !addressEditMode);
                break;
            case MyDataEditFieldsGroups.TAX_OFFICE:
                return setTaxOfficeEditMode(edit !== undefined ? edit : !taxOfficeEditMode);
                break;
            case MyDataEditFieldsGroups.BANK_ACCOUNT:
                return setBankAccountEditMode(edit !== undefined ? edit : !bankAccountEditMode);
                break;
            case MyDataEditFieldsGroups.PHONE_NUMBER:
                return setPhoneNumberEditMode(edit !== undefined ? edit : !phoneNumberEditMode);
                break;
            case MyDataEditFieldsGroups.EMAIL:
                return setEmailEditMode(edit !== undefined ? edit : !emailEditMode);
                break;
            case MyDataEditFieldsGroups.PLACE_OF_BIRTH:
                return setPlaceOfBirthEditMode(edit !== undefined ? edit : !placeOfBirthEditMode);
                break;
        }
    }

    const getEditMode = (name: string) => {
        switch (name) {
            case MyDataEditFieldsGroups.LAST_NAME:
                return lastNameEditMode;
                break;
            case MyDataEditFieldsGroups.FIRST_NAME:
                return firstNameEditMode;
                break;
            case MyDataEditFieldsGroups.SECOND_NAME:
                return secondNameEditMode;
                break;
            case MyDataEditFieldsGroups.FAMILY_NAME:
                return familyNameEditMode;
                break;
            case MyDataEditFieldsGroups.ADDRESS:
                return addressEditMode; // !addressEditMode //true;
                break;
            case MyDataEditFieldsGroups.TAX_OFFICE:
                return taxOfficeEditMode;
                break;
            case MyDataEditFieldsGroups.BANK_ACCOUNT:
                return bankAccountEditMode;
                break;
            case MyDataEditFieldsGroups.PHONE_NUMBER:
                return phoneNumberEditMode;
                break;
            case MyDataEditFieldsGroups.EMAIL:
                return emailEditMode;
                break;
            case MyDataEditFieldsGroups.PLACE_OF_BIRTH:
                return placeOfBirthEditMode;
                break;
        }
    }

    const getVisibility = (name: string) => {
        switch (name) {
            case MyDataEditFieldsGroups.LAST_NAME:
            case MyDataEditFieldsGroups.FIRST_NAME:
            case MyDataEditFieldsGroups.SECOND_NAME:
            case MyDataEditFieldsGroups.FAMILY_NAME:
            case MyDataEditFieldsGroups.TAX_OFFICE:
            case MyDataEditFieldsGroups.PHONE_NUMBER:
            case MyDataEditFieldsGroups.EMAIL:
            case MyDataEditFieldsGroups.PLACE_OF_BIRTH:
                return true;
                break;
            case MyDataEditFieldsGroups.ADDRESS:
                return addressVisible;
                break;
            case MyDataEditFieldsGroups.BANK_ACCOUNT:
                return bankAccountVisible;
                break;
        }
        ;
    }

    const changeVisibility = (name: string, visible?: boolean) => {
        switch (name) {
            case MyDataEditFieldsGroups.ADDRESS:
                return setAddressVisible(visible !== undefined ? visible : !addressVisible);
                break;
            case MyDataEditFieldsGroups.BANK_ACCOUNT:
                return setBankAccountVisible(visible !== undefined ? visible : !bankAccountVisible);
                break;
        }
    }

    const resetField = (field: IMyDataField) => {
        clearErrors(field.name);

        for (const [name, value] of Object.entries(props.data as object)) {
            let fieldName: string = field.fieldName;

            if (name == fieldName) {
                if (typeof value === 'object') {
                    // @TODO: selecty
                } else {
                    if (field.name == MyDataEditFields.PHONE) {
                        updatePhoneNumber(props.data?.phone_code + ' ' + props.data?.phone);
                    } else {
                        setValue(field.name, value);
                    }
                }
            }
        }
    }

    const resetForm = () => {
        Object.values(MyDataEditFieldsGroups).map((group) => {
            changeEditMode(group, false);
            changeVisibility(group, false);
        });
    }

    const setErrors = (err: []) => {
        for (const [key, value] of Object.entries(err)) {
            if (value['errors']) {
                let field: string;
                if (key == 'phoneCode') {
                    field = 'phone';
                } else {
                    field = key;
                }
                setError(field, {type: 'custom', message: t('form.invalidValue')});
            }
        }
    }

    const updateValue = (field: IMyDataField, value: any) => {
        if (field.onChange) {
            field.onChange(value);
        }
    };

    const handlePhoneErr = (error: boolean) => {
        if (error) {
            updatePhoneNumberError(true);
        } else {
            updatePhoneNumberError(false);
        }
    }

    const onFocus = (el: HTMLElement) => {
        setTimeout(() => {
            const element = el.parentElement;
            const pane = el.parentElement?.parentElement?.parentElement;
            const scrollBy = (element?.getBoundingClientRect().y ?? 0) - (pane?.getBoundingClientRect().y ?? 0);

            if (pane && pane.scrollHeight - pane.scrollTop - scrollBy < pane.offsetHeight) {
                pane?.scrollBy({
                    top: pane.scrollHeight - pane.scrollTop,
                    behavior: 'smooth'
                });
            } else {
                pane?.scrollBy({
                    top: scrollBy,
                    behavior: 'smooth'
                });
            }

        }, 400);
    };

    const content = useRef<HTMLDivElement>(null);

    const scrollToGroup = () => {
        content.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const fetchProfileData = async () => {
        let facebookId = await Preferences.get({'key': 'facebook_id'});

        if (facebookId && facebookId.value && facebookId.value == '1') {
            updateIsFacebookUser(true);
        }
    }

    useEffect(() => {
        fetchProfileData();
    }, []);

    return (
        <div className="content" ref={content}>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />

            <Form.Container onSubmit={handleSubmit(onSubmit)}>
                {
                    props.fields.map((field, i) =>
                        <>
                            {
                                field.name === MyDataEditFields.CITY && <StyledMyDataField>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size={getVisibility(field.group) ? '12' : '9'}>
                                                <StyledField>
                                                    <label>{t('myData.address')}</label>
                                                    {
                                                        props.data?.street && props.data?.house_number && props.data?.zip_code && props.data?.zip_code && props.data?.city?.name ?
                                                            props.data?.street + ' ' + props.data?.house_number + (props.data?.apartment_number ? '/' + props.data?.apartment_number : '') + ', ' + props.data?.zip_code + ' ' + props.data?.city?.name : '--'
                                                    }
                                                </StyledField>
                                            </IonCol>
                                            {
                                                !getVisibility(field.group) &&
                                                <IonCol size="3" className="action edit" onClick={() => {
                                                    changeVisibility(field.group);
                                                    changeEditMode(field.group);
                                                    scrollToGroup();
                                                }}>
                                                    <div>
                                                        {getVisibility(field.group) ? t('myData.cancel') : t('myData.change')}
                                                    </div>
                                                </IonCol>
                                            }
                                        </IonRow>
                                    </IonGrid>
                                    {
                                        getVisibility(field.group) &&
                                        <p className="noticeMessage">{t('myData.commonHelpText')}</p>
                                    }
                                </StyledMyDataField>
                            }

                            {
                                field.name === MyDataEditFields.BANK_ACCOUNT_NUMBER && <StyledMyDataField>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size={getVisibility(field.group) ? '12' : '9'}>
                                                <StyledField>
                                                    <label>{t('myData.bankAccount')}</label>
                                                    {
                                                        props.data?.bank_account_number + (props.data?.worker_form_currency == 'EUR' && props.data?.bicswift ? ', ' + props.data?.bicswift : '')
                                                    }
                                                </StyledField>
                                            </IonCol>
                                            {
                                                !getVisibility(field.group) &&
                                                <IonCol size="3" className="action edit" onClick={() => {
                                                    changeVisibility(field.group);
                                                    changeEditMode(field.group);
                                                    scrollToGroup();
                                                }}>
                                                    <div>
                                                        {getVisibility(field.group) ? t('myData.cancel') : t('myData.change')}
                                                    </div>
                                                </IonCol>
                                            }
                                        </IonRow>
                                    </IonGrid>
                                    {
                                        getVisibility(field.group) &&
                                        <p className="noticeMessage">{t('myData.bankAccountHelpText')}</p>
                                    }
                                </StyledMyDataField>
                            }

                            <StyledMyDataField className={getVisibility(field.group) === true ? 'visible' : 'hidden'}>

                                {/* @TODO: sprawdzamy nazwe pola i jesli pole jest ktores adresowe, to jego widzialnosc */}
                                {/* ustawiamy wedlug addressEditMode. Nie renderujemy tez pierwszego IonGrida z mozliwoscia przejscia */}
                                {/* do edycji kazdego pola osobno, tylko gdy nazwa == province, to renderujemy taki label */}
                                {/* z mozliwoscia przejscia do edycji, czyli zmiany addressEditMode i pokazania wszystkich pol adresowych */}

                                <IonGrid className={getEditMode(field.group) === true ? 'hidden' : 'visible'}>
                                    <IonRow className={field?.valueToVerify == true ? 'to-verify' : ''}>
                                        <IonCol size={field.editModeButtonVisible === true ? '9' : '12'}>
                                            <StyledField>
                                                <label>{field.label}</label>
                                                {field.displayedValue ? field.displayedValue : '--'}
                                            </StyledField>
                                        </IonCol>
                                        {
                                            field.editModeButtonVisible === true && field.valueToVerify == false &&
                                            <IonCol size="3" className="action edit" onClick={(e) => {
                                                changeEditMode(field.group);
                                                scrollToGroup();
                                            }}>
                                                <div>
                                                    {t('myData.change')}
                                                </div>
                                            </IonCol>
                                        }
                                        {
                                            field.editModeButtonVisible === true && field.valueToVerify == true &&
                                            <IonCol size="3" className="action disabled">
                                                <div>
                                                    {t('myData.change')}
                                                </div>
                                            </IonCol>
                                        }
                                    </IonRow>
                                    {field.valueToVerify == true && <IonRow>
                                        <IonCol size="12">
                                            <Info className="error">{t('myDataPage.changes.field.not_verified')}</Info>
                                        </IonCol>
                                    </IonRow>}
                                </IonGrid>

                                <IonGrid className={getEditMode(field.group) === false ? 'hidden' : 'visible'}>
                                    <IonRow style={{"paddingBottom": "0"}}>
                                        <IonCol size="12"> {/* {field.editModeButtonVisible === true ? '6' : '12'} */}
                                            <StyledField>

                                                {
                                                    field.label && <label
                                                        className={field.required ? 'required' : ''}>{field.label}</label>
                                                }

                                                {
                                                    field.type === MyDataEditType.INPUT &&
                                                    <>
                                                        <StyledInput
                                                            className={errors[field.name] ? 'bolded no-margin hasErrors' : 'bolded no-margin'}
                                                            name={field.name}
                                                            ref={register({
                                                                required: field.required
                                                            })}
                                                            type="text"
                                                            onChange={e => {
                                                                updateValue(field, e.target.value);
                                                                updateManualIsDirty(true);
                                                            }}
                                                        ></StyledInput>
                                                        {field.helpText &&
                                                            <p className="noticeMessage">{field.helpText}</p>}
                                                        {errors[field.name] && errors[field.name].message &&
                                                            <p className="errorMessage">{errors[field.name].message}</p>}
                                                    </>
                                                }

                                                {
                                                    field.type === MyDataEditType.EMAIL &&
                                                    <>
                                                        <StyledInput
                                                            className={errors[field.name] ? 'bolded no-margin hasErrors' : 'bolded no-margin'}
                                                            name={field.name}
                                                            ref={register({
                                                                required: field.required
                                                            })}
                                                            type="email"
                                                            onChange={e => {
                                                                updateValue(field, e.target.value);
                                                                updateManualIsDirty(true);
                                                            }}
                                                            /*onFocus={(e) => onFocus(e.target)}*/
                                                        ></StyledInput>
                                                        {field.helpText &&
                                                            <p className="noticeMessage">{field.helpText}</p>}
                                                        {errors[field.name] && errors[field.name].message &&
                                                            <p className="errorMessage">{errors[field.name].message}</p>}
                                                    </>
                                                }

                                                {
                                                    field.type === MyDataEditType.PHONE &&
                                                    <>
                                                        <ExactPhoneInput
                                                            name={field.name}
                                                            phoneNumer={phoneNumber}
                                                            phoneNumerChanged={(phoneNumber) => {
                                                                updatePhoneNumber(phoneNumber);
                                                                updateValue(field, phoneNumber);
                                                                updateManualIsDirty(true);
                                                            }}
                                                            errorChanged={handlePhoneErr}
                                                            errorClass="hasErrors"
                                                        ></ExactPhoneInput>
                                                        {field.helpText &&
                                                            <p className="noticeMessage">{field.helpText}</p>}
                                                        {errors[field.name] && errors[field.name].message &&
                                                            <p className="errorMessage">{errors[field.name].message}</p>}
                                                        <IonRow style={{"borderBottom": "none"}}>
                                                            <IonCol size="6"
                                                                    style={{"paddingTop": "0", "paddingLeft": "0"}}>
                                                                <Form.Button type="button"
                                                                             disabled={phoneNumberError || !manualIsDirty} /*{errors[field.name]}*/
                                                                             onClick={() => {
                                                                                 signPhoneNumber()
                                                                             }}>{t("common.save")}</Form.Button>
                                                            </IonCol>
                                                            <IonCol size="6"
                                                                    style={{"paddingTop": "0", "paddingRight": "0"}}>
                                                                <StyledWiteButton type="button" onClick={() => {
                                                                    changeEditMode(field.group);
                                                                    resetField(field);
                                                                    updateManualIsDirty(false);
                                                                }}>{t('myData.cancel')}
                                                                </StyledWiteButton>
                                                            </IonCol>
                                                        </IonRow>
                                                    </>
                                                }

                                                {
                                                    field.type === MyDataEditType.CHECKBOX && field.name == MyDataEditFields.BICSWIFT_CONFIRM &&
                                                    <IonItem lines="none">
                                                        <StyledIonLabel>{t('myData.bicSwiftHelpText')}</StyledIonLabel>
                                                        <StyledIonCheckbox slot="start"
                                                                           checked={bicswiftConfirmationChecked}
                                                                           onIonChange={(e) => {
                                                                               updateBicswiftConfirmationChecked(e.detail.checked);
                                                                               //updateManualIsDirty(true);
                                                                           }}/>
                                                    </IonItem>
                                                }

                                                {
                                                    field.group == MyDataEditFieldsGroups.ADDRESS && field.name == MyDataEditFields.POST_OFFICE &&
                                                    <>
                                                        <IonRow style={{"borderBottom": "none"}}>
                                                            <IonCol size="6"
                                                                    style={{"paddingTop": "0", "paddingLeft": "0"}}>
                                                                <Form.Button type="submit" disabled={
                                                                    errors[MyDataEditFields.CITY] ||
                                                                    errors[MyDataEditFields.ZIP_CODE] ||
                                                                    errors[MyDataEditFields.STREET] ||
                                                                    errors[MyDataEditFields.HOUSE_NUMBER] ||
                                                                    errors[MyDataEditFields.POST_OFFICE] || !manualIsDirty}>{t("common.save")}</Form.Button>
                                                            </IonCol>
                                                            <IonCol size="6"
                                                                    style={{"paddingTop": "0", "paddingRight": "0"}}>
                                                                <StyledWiteButton type="button" onClick={() => {
                                                                    changeVisibility(field.group);
                                                                    changeEditMode(field.group);
                                                                    updateManualIsDirty(false);
                                                                }}>{t('myData.cancel')}</StyledWiteButton>
                                                            </IonCol>
                                                        </IonRow>
                                                    </>
                                                }

                                                {
                                                    field.group == MyDataEditFieldsGroups.BANK_ACCOUNT && ((field.name == MyDataEditFields.BICSWIFT_CONFIRM && props.data?.worker_form_currency == 'EUR') || (field.name == MyDataEditFields.BANK_ACCOUNT_NUMBER && props.data?.worker_form_currency == 'PLN')) &&
                                                    <>
                                                        <IonRow style={{"borderBottom": "none"}}>
                                                            <IonCol size="6"
                                                                    style={{"paddingTop": "0", "paddingLeft": "0"}}>
                                                                <Form.Button type="submit"
                                                                             disabled={props.data?.worker_form_currency == 'EUR' ?
                                                                                 errors[MyDataEditFields.BANK_ACCOUNT_NUMBER] || errors[MyDataEditFields.BICSWIFT] || !bicswiftConfirmationChecked || !manualIsDirty :
                                                                                 errors[MyDataEditFields.BANK_ACCOUNT_NUMBER] || !manualIsDirty}>{t("common.save")}</Form.Button>
                                                            </IonCol>
                                                            <IonCol size="6"
                                                                    style={{"paddingTop": "0", "paddingRight": "0"}}>
                                                                <StyledWiteButton type="button" onClick={(e) => {
                                                                    changeVisibility(field.group);
                                                                    changeEditMode(field.group);
                                                                    updateManualIsDirty(false);
                                                                }}>{t('myData.cancel')}</StyledWiteButton>
                                                            </IonCol>
                                                        </IonRow>
                                                    </>
                                                }

                                                {
                                                    field.type === MyDataEditType.SELECT &&
                                                    <>
                                                        <StyledIonSelect className="bolded"
                                                                         okText={t('common.selectOk')}
                                                                         cancelText={t('common.selectCancel')}
                                                                         value={field.value?.toString()}
                                                                         disabled={field.disabled}
                                                                         name={field.name}
                                                                         justify="end"
                                                                         ref={register({
                                                                             required: field.required
                                                                         })}
                                                                         interfaceOptions={
                                                                             {
                                                                                 header: field.label
                                                                             }
                                                                         }
                                                                         onIonChange={e => {
                                                                             if (field.onChange && e.detail.value) {
                                                                                 field.onChange(e.detail.value);
                                                                                 updateManualIsDirty(true);
                                                                             }
                                                                         }}
                                                                         onIonFocus={e => {
                                                                         }}
                                                                         onIonCancel={e => {
                                                                             updateManualIsDirty(false);
                                                                         }}>
                                                            {field.options?.map((value: ContractDataSelectable, key) =>
                                                                <IonSelectOption key={key}
                                                                                 value={value.id?.toString()}>{value?.name}</IonSelectOption>)}
                                                        </StyledIonSelect>
                                                        {field.helpText &&
                                                            <p className="noticeMessage">{field.helpText}</p>}
                                                    </>
                                                }

                                                {
                                                    field.type === MyDataEditType.SELECT_CITY &&
                                                    <>
                                                        <IonCol size="12"
                                                                style={{"paddingTop": "0", "paddingLeft": "0"}}>
                                                            <StyledIonSelect className="bolded city-select"
                                                                             value={city ? city.id : field.value}
                                                                             name={field.name}
                                                                             ref={register({
                                                                                 required: field.required
                                                                             })}
                                                                             onIonChange={e => {
                                                                                 if (field.onChange && e.detail.value) {
                                                                                     field.onChange(e.detail.value);
                                                                                     updateManualIsDirty(true);
                                                                                 }
                                                                             }}
                                                                             onIonFocus={e => {
                                                                             }}
                                                                             onIonCancel={e => {
                                                                                 updateManualIsDirty(false);
                                                                             }}
                                                                             onClick={(event) => {
                                                                                 // aby się nie wyświetlał standardowy alert z wyborem pozycji
                                                                                 setTimeout(() => {
                                                                                     document.querySelector('ion-alert.select-alert')?.remove()
                                                                                 }, 10);
                                                                                 updateShowCityModal(true);
                                                                             }}
                                                            >
                                                                {!city && field.options?.map((value: ContractDataSelectable, key) =>
                                                                    <IonSelectOption key={key}
                                                                                     value={value.id?.toString()}>{value?.name}</IonSelectOption>)}
                                                                {city &&
                                                                    <IonSelectOption key={0}
                                                                                     value={city?.id}>{city?.name}</IonSelectOption>}

                                                            </StyledIonSelect>
                                                        </IonCol>
                                                    </>
                                                }

                                                {
                                                    field.group != MyDataEditFieldsGroups.ADDRESS && field.group != MyDataEditFieldsGroups.BANK_ACCOUNT && field.group != MyDataEditFieldsGroups.PHONE_NUMBER &&
                                                    <IonRow style={{"borderBottom": "none"}}>
                                                        <IonCol size="6"
                                                                style={{"paddingTop": "0", "paddingLeft": "0"}}>
                                                            <Form.Button type="submit"
                                                                         disabled={errors[field.name] || !manualIsDirty}>{t("common.save")}</Form.Button>
                                                        </IonCol>
                                                        <IonCol size="6"
                                                                style={{"paddingTop": "0", "paddingRight": "0"}}>
                                                            <StyledWiteButton type="button" onClick={() => {
                                                                changeEditMode(field.group);
                                                                resetField(field);
                                                                updateManualIsDirty(false);
                                                            }}>{t('myData.cancel')}
                                                            </StyledWiteButton>
                                                        </IonCol>
                                                    </IonRow>
                                                }
                                            </StyledField>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </StyledMyDataField>
                        </>
                    )}
            </Form.Container>

            {signModalConfiguration && <SignModal configuration={signModalConfiguration}
                                                  isOpen={showSignModal}
                                                  onClose={() => updateShowSignModal(false)}
                                                  onSave={(code: string) => signSave(code)}
                                                  formSubmitted={signFormSubmitted}>
            </SignModal>}

            {signModalConfiguration && <SignWithPasswordModal configuration={signModalConfiguration}
                                                              isOpen={showSignWithPasswordModal}
                                                              onClose={() => updateShowSignWithPasswordModal(false)}
                                                              onSave={(code: string, password: string) => signSave(code, password)}
                                                              formSubmitted={signFormSubmitted}>
            </SignWithPasswordModal>}

            <CityModal
                isOpen={showCityModal}
                updateCity={updateCity}
                closeModalCity={(city: ICity | undefined) => closeModalCity(city)}
                onClose={() => updateShowCityModal(false)}></CityModal>

            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={showAlertMessage}
                buttons={[
                    {
                        text: t('myDataPage.alert.ok'),
                        handler: () => {
                            setShowAlert(false);
                            props.refreshData();
                        }
                    }
                ]}
                backdropDismiss={false}
            />
            <IonAlert
                isOpen={showPostValidationAlert}
                backdropDismiss={false}
                header={t(validationAlertTitle)}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => handleValidationAlertCancel(),
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => handlePostSaveValidationAlertConfirm(),
                    }
                ]}
            />
        </div>
    )
};

export default MyDataForm;